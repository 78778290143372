import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { firestore } from "../../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
  deleteDoc,
} from "firebase/firestore";
import classes from "./ConnectionsModal.module.css";

const ConnectionsModal = ({
  onClose,
  currentUserId,
  loggedInUserId,
  isOwnProfile,
}) => {
  const [connections, setConnections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [openDropdown, setOpenDropdown] = useState(null);
  const [totalConnections, setTotalConnections] = useState(0);

  const handleCloseTouch = (e) => {
    e.preventDefault();
    onClose();
  };

  const checkConnectionType = async (userId, connectionId) => {
    if (isOwnProfile || userId === connectionId) return "Direct";

    const userConnectionRef = doc(
      firestore,
      `users/${userId}/connections/${connectionId}`
    );
    const userConnectionDoc = await getDoc(userConnectionRef);

    if (
      userConnectionDoc.exists() &&
      userConnectionDoc.data().status === "Connected"
    ) {
      return "Direct";
    }

    // Check for indirect connections
    const userConnectionsRef = collection(
      firestore,
      `users/${userId}/connections`
    );
    const userConnectionsQuery = query(
      userConnectionsRef,
      where("status", "==", "Connected")
    );
    const userConnectionsSnapshot = await getDocs(userConnectionsQuery);

    for (const conn of userConnectionsSnapshot.docs) {
      const indirectConnectionRef = doc(
        firestore,
        `users/${conn.id}/connections/${connectionId}`
      );
      const indirectConnectionDoc = await getDoc(indirectConnectionRef);
      if (
        indirectConnectionDoc.exists() &&
        indirectConnectionDoc.data().status === "Connected"
      ) {
        return "Indirect";
      }
    }

    return "Extended";
  };

  const renderConnectionOptions = (connection) => {
    if (isOwnProfile && connection.id !== loggedInUserId) {
      return (
        <div className={classes.optionsContainer}>
          <svg
            className={`${classes.optionsIcon} ${
              openDropdown === connection.id ? classes.optionsIconActive : ""
            }`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            fill="none"
            viewBox="0 0 24 24"
            onClick={(e) => {
              e.stopPropagation();
              setOpenDropdown(
                openDropdown === connection.id ? null : connection.id
              );
            }}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeWidth="3"
              d="M6 12h.01m6 0h.01m5.99 0h.01"
            />
          </svg>
          {openDropdown === connection.id && (
            <div className={classes.optionsDropdown}>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleRemoveConnection(connection.id);
                }}
              >
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="gray"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 8a4 4 0 1 1 8 0 4 4 0 0 1-8 0Zm-2 9a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v1a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-1Zm13-6a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2h-4Z"
                    clipRule="evenodd"
                  />
                </svg>
                Remove connection
              </button>
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  const handleRemoveConnection = async (connectionId) => {
    try {
      await deleteDoc(
        doc(firestore, `users/${loggedInUserId}/connections/${connectionId}`)
      );
      await deleteDoc(
        doc(firestore, `users/${connectionId}/connections/${loggedInUserId}`)
      );
      setConnections(connections.filter((conn) => conn.id !== connectionId));
    } catch (error) {
      console.error("Error removing connection:", error);
    }
  };

  useEffect(() => {
    const fetchConnections = async () => {
      try {
        const connectionsRef = collection(
          firestore,
          `users/${currentUserId}/connections`
        );
        const q = query(connectionsRef, where("status", "==", "Connected"));
        const querySnapshot = await getDocs(q);

        const connectionsData = await Promise.all(
          querySnapshot.docs.map(async (connectionDoc) => {
            const userDocRef = doc(firestore, "users", connectionDoc.id);
            const userDoc = await getDoc(userDocRef);
            const connectionType = await checkConnectionType(
              loggedInUserId,
              connectionDoc.id
            );
            return {
              id: connectionDoc.id,
              ...userDoc.data(),
              connectedAt:
                connectionDoc.data().connectedAt?.toDate() || new Date(0),
              connectionType,
            };
          })
        );

        // New sorting logic
        connectionsData.sort((a, b) => {
          if (a.id === loggedInUserId) return -1;
          if (b.id === loggedInUserId) return 1;
          return b.connectedAt - a.connectedAt;
        });

        setConnections(connectionsData);
        setTotalConnections(connectionsData.length); // Add this line
      } catch (error) {
        console.error("Error fetching connections:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchConnections();
  }, [currentUserId, loggedInUserId, isOwnProfile]);

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  useEffect(() => {
    const scrollY = window.scrollY;

    // Apply the scroll position to the modal backdrop
    const backdrop = document.querySelector(`.${classes.modalBackdrop}`);
    if (backdrop) {
      backdrop.style.top = `${scrollY}px`;
    }

    // Prevent body scrolling
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    document.body.style.width = "100%";

    return () => {
      // Restore body scrolling
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";

      // Don't scroll here, let Profile.js handle it
    };
  }, []);

  const handleNavigate = async (userId) => {
    onClose();
    if (userId === loggedInUserId) {
      navigate("/profile");
    } else {
      try {
        const userDoc = await getDoc(doc(firestore, "users", userId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          navigate(`/${userData.urlSlug}`);
        } else {
          console.error("User not found");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredConnections = useMemo(() => {
    return connections.filter((connection) => {
      const fullName =
        `${connection.firstName} ${connection.lastName}`.toLowerCase();
      return fullName.includes(searchTerm.toLowerCase());
    });
  }, [connections, searchTerm]);

  const formatConnectionDate = (connectedAt) => {
    const now = new Date();
    const diffTime = Math.abs(now - connectedAt);
    const diffSeconds = Math.floor(diffTime / 1000);
    const diffMinutes = Math.floor(diffSeconds / 60);
    const diffHours = Math.floor(diffMinutes / 60);
    const diffDays = Math.floor(diffHours / 24);
    const diffWeeks = Math.floor(diffDays / 7);
    const diffMonths = Math.floor(diffDays / 30);
    const diffYears = Math.floor(diffDays / 365);

    if (diffSeconds < 60)
      return `Connected ${diffSeconds} second${
        diffSeconds !== 1 ? "s" : ""
      } ago`;
    if (diffMinutes < 60)
      return `Connected ${diffMinutes} minute${
        diffMinutes !== 1 ? "s" : ""
      } ago`;
    if (diffHours < 24)
      return `Connected ${diffHours} hour${diffHours !== 1 ? "s" : ""} ago`;
    if (diffDays < 7)
      return `Connected ${diffDays} day${diffDays !== 1 ? "s" : ""} ago`;
    if (diffWeeks < 4)
      return `Connected ${diffWeeks} week${diffWeeks !== 1 ? "s" : ""} ago`;
    if (diffMonths < 12)
      return `Connected ${diffMonths} month${diffMonths !== 1 ? "s" : ""} ago`;
    return `Connected ${diffYears} year${diffYears !== 1 ? "s" : ""} ago`;
  };

  return (
    <div className={classes.modalBackdrop} onClick={onClose}>
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            {totalConnections}{" "}
            {totalConnections === 1 ? "Connection" : "Connections"}
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="none"
              viewBox="0 0 24 24"
              onClick={onClose}
              onTouchStart={handleCloseTouch}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>

          <div className={classes.searchContainer}>
            <div className={classes.inputWrapper}>
              <input
                type="text"
                placeholder="Ex: Paul Graham"
                value={searchTerm}
                onChange={handleSearchChange}
                className={classes.userInput}
              />
              <svg
                className={classes.searchIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="gray"
                  stroke-linecap="round"
                  stroke-width="2"
                  d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
          </div>
        </div>
        <form className={classes.interactions}>
          <div className={classes.connectionsList}>
            {loading ? (
              <div className={classes.loadingIndicator}>
                <span
                  className={`material-symbols-outlined ${classes.loadingIcon}`}
                >
                  progress_activity
                </span>
              </div>
            ) : filteredConnections.length > 0 ? (
              filteredConnections.map((connection) => (
                <div key={connection.id} className={classes.connectionItem}>
                  <img
                    onClick={() => handleNavigate(connection.id)}
                    src={connection.profileImage}
                    alt={`${connection.firstName} ${connection.lastName}`}
                    className={classes.profileImage}
                  />
                  <div className={classes.connectionInfo}>
                    <h3 className={classes.name}>
                      <div className={classes.nameWrapper}>
                        <span
                          className={classes.nameText}
                          onClick={() => handleNavigate(connection.id)}
                        >
                          {connection.firstName} {connection.lastName}
                        </span>
                        <span className={classes.connectionType}>
                          •{" "}
                          {connection.id === loggedInUserId
                            ? "You"
                            : connection.connectionType}
                        </span>
                      </div>
                    </h3>

                    <p
                      className={`${classes.bio} ${
                        !connection.bio && classes.noBio
                      }`}
                    >
                      {connection.bio || connection.username}
                    </p>

                    <p className={classes.connectionDate}>
                      {formatConnectionDate(connection.connectedAt)}
                    </p>
                  </div>
                  {renderConnectionOptions(connection)}
                </div>
              ))
            ) : (
              <p className={classes.default}>No connections found.</p>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ConnectionsModal;
