import React, { useState, useRef, useEffect } from "react";
import classes from "./YearDropdown.module.css";

const EducationYearDropdown = ({
  label,
  selectedYear,
  onYearChange,
  disabled,
  minYear,
  maxYear,
  onCurrentYearSelected,
  placeholder,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const dropdownRef = useRef(null);

  const currentYear = new Date().getFullYear();

  const generateYearsList = () => {
    const startYear = currentYear - 100;
    const endYear = currentYear + 10;
    return Array.from(
      { length: endYear - startYear + 1 },
      (_, i) => endYear - i
    );
  };

  const yearsList = generateYearsList();

  const filteredYears = yearsList.filter(
    (year) => year >= (minYear || 1900) && year <= (maxYear || currentYear + 10)
  );

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (year) => {
    onYearChange(year);
    if (parseInt(year) === currentYear && onCurrentYearSelected) {
      onCurrentYearSelected();
    }
    setIsOpen(false);
    setHighlightedIndex(-1);
  };

  const handleKeyDown = (e) => {
    if (!isOpen) return;

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setHighlightedIndex((prevIndex) =>
          Math.min(prevIndex + 1, filteredYears.length - 1)
        );
        break;
      case "ArrowUp":
        e.preventDefault();
        setHighlightedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        break;
      case "Enter":
        e.preventDefault();
        if (highlightedIndex >= 0) {
          handleSelect(filteredYears[highlightedIndex]);
        }
        break;
      case "Escape":
        setIsOpen(false);
        break;
      default:
        break;
    }
  };

  return (
    <div className={classes.customDropdown} ref={dropdownRef}>
      <label className={classes.label}>{label}</label>
      <div className={classes.dropdownContainer}>
        <div
          className={`${classes.dropdownHeader} ${
            disabled ? classes.disabled : ""
          } ${isOpen ? classes.active : ""}`}
          onClick={() => !disabled && toggleDropdown()}
          onKeyDown={handleKeyDown}
          tabIndex={0}
        >
          <span
            className={
              selectedYear ? classes.selectedText : classes.placeholderText
            }
          >
            {selectedYear || placeholder}
          </span>
          <span className={classes.chevron}>
            {isOpen ? (
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.75"
                  d="m16 14-4-4-4 4"
                />
              </svg>
            ) : (
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.75"
                  d="m8 10 4 4 4-4"
                />
              </svg>
            )}
          </span>
        </div>
        {isOpen && !disabled && (
          <div className={classes.dropdownListContainer}>
            <ul className={classes.dropdownList}>
              {filteredYears.map((year, index) => (
                <li
                  key={year}
                  className={`${classes.dropdownListItem} ${
                    index === highlightedIndex ? classes.highlightedItem : ""
                  }`}
                  onClick={() => handleSelect(year)}
                  onMouseEnter={() => setHighlightedIndex(index)}
                >
                  {year}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default EducationYearDropdown;
