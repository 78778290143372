import icon from "../../assets/images/icon.png";
import classes from "./Navbar.module.css";
import { Link } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { waitlist } from "../../utils/waitlistForm";
import rounded from "../../assets/icons/rounded.png";

function Navbar() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isCompanyDropdownOpen, setIsCompanyDropdownOpen] = useState(false);
  const [isMenuIconClicked, setIsMenuIconClicked] = useState(false);
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const handleSignUp = (e) => {
    e.preventDefault();
    waitlist(email, setSuccess, setError);
  };

  const menuRef = useRef(null);

  useEffect(() => {
    if (isMenuIconClicked) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuIconClicked(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuIconClicked]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleMenuIcon = () => {
    setIsMenuIconClicked(!isMenuIconClicked);
  };

  const toggleCompanyDropdown = () => {
    setIsCompanyDropdownOpen(!isCompanyDropdownOpen);
  };

  return (
    <nav className={classes.nav}>
      <div className={classes.navItems}>
        <Link to="/">
          <img src={icon} alt="" className={classes.icon} />
        </Link>
        <Link to="/">
          <div className={classes.logo}>Foundry</div>
        </Link>
      </div>
      {/* Desktop */}
      <div className={classes.menuItem} onClick={toggleDropdown}>
        <div
          className={`${classes.company} ${
            isDropdownOpen ? classes.underlined : ""
          }`}
        >
          {" "}
          Company{" "}
        </div>
        <span
          className="material-symbols-outlined"
          style={{ fontSize: "1.125rem", marginTop: "0.125rem" }}
        >
          {isDropdownOpen ? "keyboard_arrow_up" : "keyboard_arrow_down"}
        </span>
        {isDropdownOpen && (
          <div className={classes.dropdown}>
            <Link to="/">About</Link>
            {/* <Link to="/">Blog</Link> */}
            <Link to="/careers">Careers at Foundry</Link>
          </div>
        )}
      </div>

      {/* Mobile */}
      <div className={classes.menuIconContainer} ref={menuRef}>
        <div className={classes.menuIcon} onClick={toggleMenuIcon}>
          <span
            className="material-symbols-outlined"
            style={{
              fontSize: "1.6875rem",
              marginTop: "0.28125rem",
              marginRight: "-0.28125rem",
            }}
          >
            {isMenuIconClicked ? "close" : "menu"}
          </span>
        </div>
        {isMenuIconClicked && (
          <div className={classes.menu}>
            <div
              className={classes.companyDropdown}
              onClick={toggleCompanyDropdown}
            >
              <div className={classes.menuCompany}>
                Company{" "}
                <span
                  className="material-symbols-outlined"
                  style={{
                    fontSize: "1.4063rem",
                    marginTop: "0.28125rem",
                    marginRight: "-0.28125rem",
                  }}
                >
                  {isCompanyDropdownOpen
                    ? "keyboard_arrow_up"
                    : "keyboard_arrow_down"}
                </span>
              </div>

              {isCompanyDropdownOpen && (
                <div className={classes.menuDropdown}>
                  <Link to="/">About</Link>
                  {/* <Link to="/">Blog</Link> */}
                  <Link to="/careers"> Careers at Foundry</Link>
                </div>
              )}
              <div className={classes.border}></div>
            </div>
            <Link className={classes.hiddenLink} to="/login">
              a
            </Link>

            <form onSubmit={handleSignUp}>
              <input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={classes.userInput}
              />
              <button type="submit" className={classes.primaryButton}>
                Join waitlist
              </button>
            </form>
            {success === true && (
              <p className={classes.success}>
                <div className={classes.menuMessage}>
                  <div className={classes.icons}>
                    <span class="material-symbols-outlined">check_circle</span>
                  </div>
                  You're now on the waitlist!
                </div>
              </p>
            )}
            {error && (
              <p className={classes.error}>
                <div className={classes.menuMessage}>
                  <div className={classes.icons}>
                    <span class="material-symbols-outlined">error</span>
                  </div>
                  {error}
                </div>
              </p>
            )}
          </div>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
