import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { firestore, storage } from "../../../firebase";
import OnboardingNavbar from "../../../components/Navbars/OnboardingNavbar";
import profileImage from "../../../assets/icons/profileImage.jpg";
import classes from "./SignUp.module.css";
import {
  generateUrlSlug,
  updateUserUrlSlug,
} from "../../../utils/urlSlugUtils"; // You'll need to create this file

function SignUp() {
  const navigate = useNavigate();
  const { signUp, currentUser } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentUser) {
      const checkFirestoreFields = async () => {
        try {
          const userDocRef = doc(firestore, "users", currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);
          const userData = userDocSnap.data();

          if (
            userData &&
            userData.firstName &&
            userData.lastName &&
            userData.country &&
            userData.city &&
            userData.countryISO2
          ) {
            navigate("/profile");
          } else {
            navigate("/onboarding");
          }
        } catch (error) {}
      };

      checkFirestoreFields();
    }
  }, [currentUser, navigate]);

  const handleEmailChange = (e) => {
    const emailValue = e.target.value.toLowerCase();
    setEmail(emailValue);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(emailValue));
  };

  const handlePasswordChange = (e) => {
    const passwordValue = e.target.value;
    setPassword(passwordValue);
    setIsPasswordValid(passwordValue.length >= 6);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEmailValid && isPasswordValid) {
      setLoading(true);
      setError("");
      try {
        const userCredential = await signUp(email, password);
        const user = userCredential.user;

        const defaultImageRef = ref(
          storage,
          `profileImages/${user.uid}/default.jpg`
        );
        const response = await fetch(profileImage);
        const blob = await response.blob();

        await uploadBytes(defaultImageRef, blob);

        const profileImageUrl = await getDownloadURL(defaultImageRef);

        // Generate a temporary URL slug based on the email
        const tempFirstName = email.split("@")[0];
        const tempLastName = "user";
        const urlSlug = await generateUrlSlug(tempFirstName, tempLastName);

        await setDoc(doc(firestore, "users", user.uid), {
          email: user.email,
          firstName: "",
          lastName: "",
          country: "",
          countryISO2: "",
          city: "",
          bio: "",
          intro: "",
          link: "",
          linkText: "",
          profileImage: profileImageUrl,
          urlSlug: urlSlug, // Add this line
        });

        navigate("/onboarding");
      } catch (error) {
        if (error.code === "auth/email-already-in-use") {
          setError("Someone's already using that email.");
        } else if (error.code === "auth/invalid-email") {
          setError("Invalid email.");
        } else {
          setError(error.message);
        }
      } finally {
        setLoading(false);
      }
    } else {
      setError("Please enter a valid email and password.");
    }
  };

  const isFormValid = isEmailValid && isPasswordValid;

  return (
    <div className={classes.page}>
      <OnboardingNavbar />
      <div className={classes.content}>
        <div className={classes.formContainer}>
          <div className={classes.formContent}>
            <div className={classes.formAbout}>
              <p className={classes.primaryAbout}>Make ideas happen</p>
            </div>
            <form className={classes.interactions} onSubmit={handleSubmit}>
              <label htmlFor="email" className={classes.label}>
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                placeholder="Ex: joe@example.com"
                className={classes.email}
                value={email}
                onChange={handleEmailChange}
                disabled={loading}
              />
              <label htmlFor="password" className={classes.label}>
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                placeholder="Create your password"
                className={classes.email}
                value={password}
                onChange={handlePasswordChange}
                autoComplete="new-password"
                disabled={loading}
              />
              <div className={classes.smallPrint}>
                <p>Passwords must contain at least 6 characters.</p>
              </div>
              <button
                type="submit"
                className={`${classes.button} ${
                  loading ? classes.loading : ""
                }`}
                disabled={!isFormValid || loading}
              >
                {loading ? (
                  <span
                    className={`material-symbols-outlined ${classes.loadingIcon}`}
                  >
                    progress_activity
                  </span>
                ) : (
                  "Continue"
                )}
              </button>

              <div className={classes.buttonMessage}>
                <p>
                  By clicking Continue, you agree to Foundry's{" "}
                  <a href="https://foundry.bz" className={classes.link}>
                    User Agreement
                  </a>
                  ,{" "}
                  <a href="https://foundry.bz" className={classes.link}>
                    Privacy Policy
                  </a>
                  , and{" "}
                  <a href="https://foundry.bz" className={classes.link}>
                    Cookie Policy
                  </a>
                  .
                </p>
              </div>
              {error && (
                <p className={classes.error}>
                  <span
                    className={`material-symbols-outlined ${classes.errorIcon}`}
                  >
                    error
                  </span>
                  {error}
                </p>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
