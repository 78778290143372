import { firestore } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";

export async function generateUrlSlug(firstName, lastName) {
  const baseSlug = `${firstName.toLowerCase()}${lastName.toLowerCase()}`;
  let slug = baseSlug;
  let counter = 1;

  while (true) {
    const q = query(
      collection(firestore, "users"),
      where("urlSlug", "==", slug)
    );
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return slug;
    }

    counter++;
    slug = `${baseSlug}${counter}`;
  }
}

export async function updateUserUrlSlug(userId, firstName, lastName) {
  const userRef = doc(firestore, "users", userId);
  const newSlug = await generateUrlSlug(firstName, lastName);
  await updateDoc(userRef, { urlSlug: newSlug });
  return newSlug;
}
