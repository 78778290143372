import React, { useState, useEffect } from "react";
import classes from "./EmojiPicker.module.css";

const allEmojis = [
  "😀",
  "😃",
  "😄",
  "😁",
  "😆",
  "🥹",
  "😅",
  "😂",
  "🤣",
  "🥲",
  "😊",
  "😇",
  "🙂",
  "🙃",
  "😉",
  "😌",
  "😍",
  "🥰",
  "😘",
  "😗",
  "😙",
  "😚",
  "😋",
  "😛",
  "😝",
  "😜",
  "🤪",
  "🤨",
  "🧐",
  "🤓",
  "😎",
  "🥸",
  "🤩",
  "🥳",
  "😏",
  "😒",
  "😞",
  "😔",
  "😟",
  "😕",
  "🙁",
  "😣",
  "😖",
  "😫",
  "😩",
  "🥺",
  "😢",
  "😭",
  "😤",
  "😠",
  "😡",
  "🤬",
  "🤯",
  "😳",
  "🥵",
  "🥶",
  "😶‍🌫️",
  "😱",
  "😨",
  "😰",
  "😥",
  "😓",
  "🤗",
  "🤔",
  "🫣",
  "🤭",
  "🫢",
  "🫡",
  "🤫",
  "🫠",
  "🤥",
  "😶",
  "🫥",
  "😐",
  "🫤",
  "😑",
  "🫨",
  "😬",
  "🙄",
  "😯",
  "😦",
  "😧",
  "😮",
  "😲",
  "🥱",
  "😴",
  "🤤",
  "😪",
  "😮‍💨",
  "😵",
  "😵‍💫",
  "🤐",
  "🥴",
  "🤢",
  "🤮",
  "🤧",
  "😷",
  "🤒",
  "🤕",
  "🤑",
  "🤠",
  "😈",
  "👿",
  "👹",
  "👺",
  "🤡",
  "💩",
  "👻",
  "💀",
  "👽",
  "👾",
  "🤖",
  "🎃",
  "😺",
  "😸",
  "😹",
  "😻",
  "😼",
  "😽",
  "🙀",
  "😿",
  "😾",
  "🫶",
  "🤲",
  "👐",
  "🙌",
  "👏",
  "🤝",
  "👍",
  "👎",
  "👊",
  "✊",
  "🤛",
  "🤜",
  "🤞",
  "🫰",
  "🤟",
  "🤘",
  "👌",
  "🤌",
  "🤏",
  "👈",
  "👉",
  "👆",
  "👇",
  "✋",
  "🤚",
  "🖐️",
  "🖖",
  "👋",
  "🤙",
  "💪",
  "🦾",
  "🙏",
  "🫵",
  "🦶",
  "🦵",
  "🦿",
  "💄",
  "💋",
  "👄",
  "🦷",
  "👅",
  "👂",
  "🦻",
  "👃",
  "👣",
  "👁️",
  "👀",
  "🫀",
  "🫁",
  "🧠",
  "🗣️",
  "👤",
  "👥",
  "🫂",
  "👶",
  "👧",
  "🧒",
  "👦",
  "👩",
  "🧑",
  "👨",
  "👩‍🦱",
  "🧑‍🦱",
  "👩‍🦰",
  "🧑‍🦰",
  "👨‍🦰",
  "👱‍♀️",
  "👱",
  "👱‍♂️",
  "👩‍🦳",
  "🧑‍🦳",
  "👨‍🦳",
  "👩‍🦲",
  "🧔‍♀️",
  "🧔",
  "🧔‍♂️",
  "👵",
  "🧓",
  "👴",
  "👲",
  "👳‍♀️",
  "👳",
  "👳‍♂️",
  "🧕",
  "👮‍♀️",
  "👮",
  "👮‍♂️",
  "👷‍♀️",
  "👷",
  "👷‍♂️",
  "💂‍♀️",
  "🕵️‍♀️",
  "🕵️‍♂️",
  "👩‍⚕️",
  "🧑‍⚕️",
  "👨‍⚕️",
  "👨‍⚕️",
  "👩‍🌾",
  "🧑‍🌾",
  "👨‍🌾",
  "👩‍🍳",
  "🧑‍🍳",
  "👨‍🍳",
  "👩‍🎓",
  "🧑‍🎓",
  "👨‍🎓",
  "👩‍🎤",
  "🧑‍🎤",
  "👨‍🎤",
  "👩‍🏫",
  "🧑‍🏫",
  "👨‍🏫",
  "👩‍🏭",
  "🧑‍🏭",
  "👨‍🏭",
  "👩‍💻",
  "🧑‍💻",
  "👨‍💻",
  "👩‍💼",
  "🧑‍💼",
  "👨‍💼",
  "👩‍🔧",
  "🧑‍🔧",
  "👨‍🔧",
  "👩‍🔬",
  "👨‍🔬",
  "👩‍🎨",
  "🧑‍🎨",
  "👨‍🎨",
  "👩‍🚒",
  "🧑‍🚒",
  "👨‍🚒",
  "👩‍✈️",
  "🧑‍✈️",
  "👨‍✈️",
  "👩‍🚀",
  "🧑‍🚀",
  "👨‍🚀",
  "👩‍⚖️",
  "🧑‍⚖️",
  "👨‍⚖️",
  "👰‍♀️",
  "👰",
  "👰‍♂️",
  "🤵‍♀️",
  "🤵",
  "🤵",
  "👸",
  "🫅",
  "🤴",
  "🥷",
  "🦸‍♀️",
  "🦸",
  "🦸‍♂️",
  "🦹‍♀️",
  "🦹",
  "🦹‍♂️",
  "🤶",
  "🧑‍🎄",
  "🎅",
  "🧙‍♀️",
  "🧙",
  "🧙‍♂️",
  "🧝‍♀️",
  "🧝",
  "🧝‍♂️",
  "🧌",
  "🧛‍♀️",
  "🧛",
  "🧛‍♂️",
  "🧟‍♀️",
  "🧟",
  "🧟‍♂️",
  "🧞‍♀️",
  "🧞",
  "🧞‍♂️",
  "🧜‍♀️",
  "🧜",
  "🧜‍♂️",
  "🧚‍♀️",
  "🧚",
  "🧚‍♂️",
  "👼",
  "🤰",
  "🫄",
  "🫃",
  "🤱",
  "👩‍🍼",
  "🧑‍🍼",
  "👨‍🍼",
  "🙇‍♀️",
  "🙇",
  "🙇‍♂️",
  "💁‍♀️",
  "💁",
  "💁‍♂️",
  "🙅‍♀️",
  "🙅",
  "🙅‍♂️",
  "🙆‍♀️",
  "🙆",
  "🙆‍♂️",
  "🙋‍♀️",
  "🙋",
  "🙋‍♂️",
  "🧏‍♀️",
  "🧏",
  "🧏‍♂️",
  "🤦‍♀️",
  "🤦",
  "🤦‍♂️",
  "🤷‍♀️",
  "🤷",
  "🤷‍♂️",
  "🙎‍♀️",
  "🙎",
  "🙎‍♂️",
  "🙍‍♀️",
  "🙍",
  "🙍‍♂️",
  "💇‍♀️",
  "💇",
  "💇‍♂️",
  "💆‍♀️",
  "💆",
  "💆‍♂️",
  "🧖‍♀️",
  "🧖",
  "🧖‍♂️",
  "💅",
  "🤳",
  "💃",
  "🕺",
  "👯‍♀️",
  "👯",
  "👯‍♂️",
  "🕴️",
  "👩‍🦽",
  "🧑‍🦽",
  "👨‍🦽",
  "👩‍🦼",
  "🧑‍🦼",
  "👨‍🦼",
  "🚶‍♀️",
  "🚶",
  "🚶‍♂️",
  "👩‍🦯",
  "🧑‍🦯",
  "👨‍🦯",
  "🧎‍♀️",
  "🧎",
  "🧎‍♂️",
  "🏃‍♀️",
  "🏃",
  "🏃‍♂️",
  "🧍‍♀️",
  "🧍",
  "🧍‍♂️",
  "👩🏽‍🤝‍👨🏿",
  "👩🏻‍🤝‍👩🏾",
  "👨🏾‍🤝‍👨🏼",
  "👩‍❤️‍👨",
  "👩‍❤️‍👩",
  "💑",
  "👨‍❤️‍👨",
  "👩‍❤️‍💋‍👨",
  "👩‍❤️‍💋‍👩",
  "💏",
  "👨‍👩‍👦",
  "👨‍👩‍👧",
  "👨‍👩‍👧‍👦",
  "👨‍👩‍👦‍👦",
  "👨‍👩‍👧‍👧",
  "👩‍👩‍👦",
  "👩‍👩‍👧",
  "👩‍👩‍👧‍👦",
  "👩‍👩‍👦‍👦",
  "👩‍👩‍👧‍👧",
  "👨‍👨‍👦",
  "👨‍👨‍👧",
  "👨‍👨‍👧‍👦",
  "👨‍👨‍👦‍👦",
  "👨‍👨‍👧‍👧",
  "👩‍👦",
  "👩‍👧",
  "👩‍👧‍👦",
  "👩‍👦‍👦",
  "👩‍👧‍👧",
  "👨‍👦",
  "👨‍👧",
  "👨‍👧‍👦",
  "👨‍👦‍👦",
  "👨‍👧‍👧",
  "🪢",
  "🧶",
  "🧵",
  "🪡",
  "🧥",
  "🥼",
  "🦺",
  "👚",
  "👕",
  "👖",
  "🩲",
  "🩳",
  "👔",
  "👗",
  "👙",
  "🩱",
  "👘",
  "🥻",
  "🩴",
  "🥿",
  "👠",
  "👡",
  "👢",
  "👞",
  "👟",
  "🥾",
  "🧦",
  "🧤",
  "🧣",
  "🎩",
  "🧢",
  "👒",
  "🎓",
  "⛑️",
  "🪖",
  "👑",
  "💍",
  "👝",
  "👛",
  "👜",
  "💼",
  "🎒",
  "🧳",
  "👓",
  "🕶️",
  "🥽",
  "🌂",
  "🐶",
  "🐱",
  "🐭",
  "🐹",
  "🐰",
  "🦊",
  "🐻",
  "🐼",
  "🐻‍❄️",
  "🐨",
  "🐯",
  "🦁",
  "🐮",
  "🐷",
  "🐽",
  "🐸",
  "🐵",
  "🙈",
  "🙉",
  "🙊",
  "🐒",
  "🐔",
  "🐧",
  "🐦",
  "🐤",
  "🐣",
  "🐥",
  "🪿",
  "🦆",
  "🐦‍⬛",
  "🦅",
  "🦉",
  "🦇",
  "🐺",
  "🐗",
  "🐴",
  "🦄",
  "🫎",
  "🐝",
  "🪱",
  "🐛",
  "🦋",
  "🐌",
  "🐞",
  "🐜",
  "🪰",
  "🪲",
  "🪳",
  "🦟",
  "🦗",
  "🕷️",
  "🕸️",
  "🦂",
  "🐢",
  "🐍",
  "🦎",
  "🦖",
  "🦕",
  "🐙",
  "🦑",
  "🪼",
  "🦐",
  "🦞",
  "🦀",
  "🐡",
  "🐠",
  "🐟",
  "🐬",
  "🐳",
  "🐋",
  "🦈",
  "🦭",
  "🐊",
  "🐅",
  "🐆",
  "🦓",
  "🦍",
  "🦧",
  "🦣",
  "🐘",
  "🦛",
  "🦏",
  "🐪",
  "🐫",
  "🦒",
  "🦘",
  "🦬",
  "🐃",
  "🐂",
  "🐄",
  "🫏",
  "🐎",
  "🐖",
  "🐏",
  "🐑",
  "🦙",
  "🐐",
  "🦌",
  "🐕",
  "🐩",
  "🦮",
  "🐕‍🦺",
  "🐈",
  "🐈‍⬛",
  "🪶",
  "🪽",
  "🐓",
  "🦃",
  "🦤",
  "🦚",
  "🦜",
  "🦢",
  "🦩",
  "🕊️",
  "🐇",
  "🦝",
  "🦨",
  "🦡",
  "🦫",
  "🦦",
  "🦥",
  "🐁",
  "🐀",
  "🐿️",
  "🦔",
  "🐾",
  "🐉",
  "🐲",
  "🌵",
  "🎄",
  "🌲",
  "🌳",
  "🌴",
  "🪵",
  "🌱",
  "🌿",
  "🍀",
  "🎍",
  "🪴",
  "🎋",
  "🍃",
  "🍂",
  "🍁",
  "🪺",
  "🪹",
  "🍄",
  "🐚",
  "🪸",
  "🪨",
  "🌾",
  "💐",
  "🌷",
  "🌹",
  "🥀",
  "🪻",
  "🪷",
  "🌺",
  "🌸",
  "🌼",
  "🌻",
  "🌞",
  "🌝",
  "🌛",
  "🌜",
  "🌚",
  "🌕",
  "🌖",
  "🌗",
  "🌘",
  "🌑",
  "🌒",
  "🌓",
  "🌔",
  "🌙",
  "🌎",
  "🌍",
  "🌏",
  "🪐",
  "💫",
  "⭐️",
  "🌟",
  "✨",
  "💥",
  "🔥",
  "🌪️",
  "🌈",
  "🌤️",
  "⛅️",
  "🌥️",
  "🌦️",
  "🌧️",
  "⛈️",
  "🌩️",
  "🌨️",
  "⛄️",
  "🌬️",
  "💨",
  "💧",
  "💦",
  "🫧",
  "🌊",
  "🌫️",
  "🍏",
  "🍎",
  "🍐",
  "🍊",
  "🍋",
  "🍌",
  "🍉",
  "🍇",
  "🍓",
  "🫐",
  "🍈",
  "🍒",
  "🍑",
  "🥭",
  "🍍",
  "🥥",
  "🥝",
  "🍅",
  "🍆",
  "🥑",
  "🫛",
  "🥦",
  "🥬",
  "🥒",
  "🌶️",
  "🫑",
  "🌽",
  "🥕",
  "🫒",
  "🧄",
  "🧅",
  "🥔",
  "🍠",
  "🫚",
  "🥐",
  "🥯",
  "🍞",
  "🥖",
  "🥨",
  "🧀",
  "🥚",
  "🍳",
  "🧈",
  "🥞",
  "🧇",
  "🥓",
  "🥩",
  "🍗",
  "🍖",
  "🦴",
  "🌭",
  "🍔",
  "🍟",
  "🍕",
  "🫓",
  "🥪",
  "🥙",
  "🧆",
  "🌮",
  "🌯",
  "🫔",
  "🥗",
  "🥘",
  "🫕",
  "🥫",
  "🫙",
  "🍝",
  "🍜",
  "🍲",
  "🍛",
  "🍣",
  "🍱",
  "🥟",
  "🦪",
  "🍤",
  "🍙",
  "🍚",
  "🍘",
  "🍥",
  "🥠",
  "🥮",
  "🍢",
  "🍡",
  "🍧",
  "🍨",
  "🍦",
  "🥧",
  "🧁",
  "🍰",
  "🎂",
  "🍮",
  "🍭",
  "🍬",
  "🍫",
  "🍿",
  "🍩",
  "🍪",
  "🌰",
  "🥜",
  "🫘",
  "🍯",
  "🥛",
  "🫗",
  "🍼",
  "🫖",
  "🍵",
  "🧃",
  "🥤",
  "🧋",
  "🍶",
  "🍺",
  "🍻",
  "🥂",
  "🍷",
  "🥃",
  "🍸",
  "🍹",
  "🧉",
  "🍾",
  "🧊",
  "🥄",
  "🍴",
  "🍽️",
  "🥣",
  "🥡",
  "🥢",
  "🧂",
  "⚽️",
  "🏀",
  "🏈",
  "⚾️",
  "🥎",
  "🎾",
  "🏐",
  "🏉",
  "🥏",
  "🎱",
  "🪀",
  "🏓",
  "🏸",
  "🏒",
  "🏑",
  "🥍",
  "🏏",
  "🪃",
  "🥅",
  "⛳️",
  "🪁",
  "🛝",
  "🏹",
  "🎣",
  "🤿",
  "🥊",
  "🥋",
  "🎽",
  "🛹",
  "🛼",
  "🛷",
  "⛸️",
  "🥌",
  "🎿",
  "⛷️",
  "🏂",
  "🪂",
  "🏋️‍♀️",
  "🏋️",
  "🏋️‍♂️",
  "🤼‍♀️",
  "🤼",
  "🤼‍♂️",
  "🤸‍♀️",
  "🤸",
  "🤸‍♂️",
  "⛹️‍♀️",
  "⛹️",
  "⛹️‍♂️",
  "🤺",
  "🤾‍♀️",
  "🤾",
  "🤾‍♂️",
  "🏌️‍♀️",
  "🏌️",
  "🏌️‍♂️",
  "🏇",
  "🧘‍♀️",
  "🧘",
  "🧘‍♂️",
  "🏄‍♀️",
  "🏄",
  "🏄‍♂️",
  "🏊‍♀️",
  "🏊",
  "🏊‍♂️",
  "🤽‍♀️",
  "🤽",
  "🤽‍♂️",
  "🚣‍♀️",
  "🚣",
  "🚣‍♂️",
  "🧗‍♀️",
  "🧗",
  "🧗‍♂️",
  "🚵‍♀️",
  "🚵",
  "🚵‍♂️",
  "🚴‍♀️",
  "🚴",
  "🚴‍♂️",
  "🏆",
  "🥇",
  "🥈",
  "🥉",
  "🏅",
  "🎖️",
  "🏵️",
  "🎗️",
  "🎫",
  "🎟️",
  "🎪",
  "🤹‍♀️",
  "🤹",
  "🤹‍♂️",
  "🎭",
  "🩰",
  "🎨",
  "🎬",
  "🎤",
  "🎼",
  "🎹",
  "🪇",
  "🥁",
  "🪘",
  "🎷",
  "🎺",
  "🪗",
  "🎸",
  "🪕",
  "🎻",
  "🪈",
  "🎲",
  "🎯",
  "🎳",
  "🎮",
  "🎰",
  "🧩",
];

const EmojiPicker = ({ onEmojiClick, onClose }) => {
  const [recentEmojis, setRecentEmojis] = useState([]);

  useEffect(() => {
    const storedRecent = localStorage.getItem("recentEmojis");
    if (storedRecent) {
      setRecentEmojis(JSON.parse(storedRecent));
    }
  }, []);

  const handleEmojiClick = (emoji) => {
    if (typeof onEmojiClick === "function") {
      onEmojiClick(emoji);
    }
    const updatedRecent = [
      emoji,
      ...recentEmojis.filter((e) => e !== emoji),
    ].slice(0, 10);
    setRecentEmojis(updatedRecent);
    localStorage.setItem("recentEmojis", JSON.stringify(updatedRecent));
  };

  return (
    <div className={classes.emojiPicker}>
      <div className={classes.content}>
        {recentEmojis.length > 0 && (
          <div className={classes.recentSection}>
            <div className={classes.recents}>Recents</div>
            <div className={classes.recentEmojiGrid}>
              {recentEmojis.map((emoji, index) => (
                <div key={index} className={classes.emojiWrapper}>
                  <button onClick={() => handleEmojiClick(emoji)}>
                    {emoji}
                  </button>
                </div>
              ))}
            </div>
            <hr className={classes.divider} />
          </div>
        )}

        <div className={classes.emojiGrid}>
          {allEmojis.map((emoji, index) => (
            <div key={index} className={classes.emojiWrapper}>
              <button onClick={() => handleEmojiClick(emoji)}>{emoji}</button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EmojiPicker;
