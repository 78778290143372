import React, { useState, useEffect, useMemo } from "react";
import { firestore } from "../../firebase";
import {
  collection,
  getDoc,
  doc,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import classes from "./RepostsModal.module.css";
import { useNavigate } from "react-router-dom";

const RepostsModal = ({ onClose, postId, reposts, currentUserId }) => {
  const [users, setUsers] = useState({});
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      const userIds = Object.keys(reposts);
      const fetchedUsers = {};
      for (const userId of userIds) {
        const userDoc = await getDoc(doc(firestore, "users", userId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const connectionType = await checkConnectionType(
            currentUserId,
            userId
          );

          // Fetch the repostedAt timestamp
          const repostedPostDoc = await getDoc(
            doc(firestore, `users/${userId}/reposts/${postId}`)
          );
          const repostedAt = repostedPostDoc.exists()
            ? repostedPostDoc.data().repostedAt
            : null;

          fetchedUsers[userId] = { ...userData, connectionType, repostedAt };
        }
      }
      setUsers(fetchedUsers);
      setLoading(false);
    };

    fetchUsers();
  }, [reposts, currentUserId, postId]);

  const filteredReposts = useMemo(() => {
    return Object.entries(reposts).filter(([userId]) => {
      const user = users[userId];
      if (!user) return false;
      const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
      return fullName.includes(searchTerm.toLowerCase());
    });
  }, [reposts, users, searchTerm]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleNavigate = async (userId) => {
    onClose();
    if (userId === currentUserId) {
      navigate("/profile");
    } else {
      try {
        const userDoc = await getDoc(doc(firestore, "users", userId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          navigate(`/${userData.urlSlug}`);
        } else {
          console.error("User not found");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
  };

  useEffect(() => {
    const scrollY = window.scrollY;

    // Apply the scroll position to the modal backdrop
    const backdrop = document.querySelector(`.${classes.modalBackdrop}`);
    if (backdrop) {
      backdrop.style.top = `${scrollY}px`;
    }

    // Prevent body scrolling
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    document.body.style.width = "100%";

    return () => {
      // Restore body scrolling
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";

      // Don't scroll here, let Profile.js handle it
    };
  }, []);

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  const formatTimeDifference = (timestamp) => {
    if (!timestamp) return "";

    const now = new Date();
    const repostTime = timestamp.toDate();
    const diffInSeconds = Math.floor((now - repostTime) / 1000);

    if (diffInSeconds < 60) return "now";
    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) return `${diffInMinutes}m`;
    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) return `${diffInHours}h`;
    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 7) return `${diffInDays}d`;
    const diffInWeeks = Math.floor(diffInDays / 7);
    if (diffInWeeks < 4) return `${diffInWeeks}w`;
    const diffInMonths = Math.floor(diffInDays / 30);
    if (diffInMonths < 12) return `${diffInMonths}mo`;
    const diffInYears = Math.floor(diffInDays / 365);
    return `${diffInYears}y`;
  };

  const sortedReposts = useMemo(() => {
    return Object.entries(reposts)
      .map(([userId]) => ({ userId, ...users[userId] }))
      .sort((a, b) => {
        if (a.userId === currentUserId) return -1;
        if (b.userId === currentUserId) return 1;
        if (!a.repostedAt) return 1;
        if (!b.repostedAt) return -1;
        return b.repostedAt.toMillis() - a.repostedAt.toMillis();
      });
  }, [reposts, users, currentUserId]);

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  const checkConnectionType = async (userId, connectionId) => {
    if (userId === connectionId) return "You";

    const userConnectionRef = doc(
      firestore,
      `users/${userId}/connections/${connectionId}`
    );
    const userConnectionDoc = await getDoc(userConnectionRef);

    if (
      userConnectionDoc.exists() &&
      userConnectionDoc.data().status === "Connected"
    ) {
      return "Direct";
    }

    // Check for indirect connections
    const userConnectionsRef = collection(
      firestore,
      `users/${userId}/connections`
    );
    const userConnectionsQuery = query(
      userConnectionsRef,
      where("status", "==", "Connected")
    );
    const userConnectionsSnapshot = await getDocs(userConnectionsQuery);

    for (const conn of userConnectionsSnapshot.docs) {
      const indirectConnectionRef = doc(
        firestore,
        `users/${conn.id}/connections/${connectionId}`
      );
      const indirectConnectionDoc = await getDoc(indirectConnectionRef);
      if (
        indirectConnectionDoc.exists() &&
        indirectConnectionDoc.data().status === "Connected"
      ) {
        return "Indirect";
      }
    }

    return "Extended";
  };

  const handleCloseTouch = (e) => {
    e.preventDefault();
    onClose();
  };

  return (
    <div className={classes.modalBackdrop} onClick={onClose}>
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            {Object.keys(reposts).length}{" "}
            {Object.keys(reposts).length === 1 ? "Repost" : "Reposts"}
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              fill="none"
              viewBox="0 0 24 24"
              onClick={onClose}
              onTouchStart={handleCloseTouch}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>
        </div>

        <div className={classes.repostsList}>
          {loading ? (
            <div className={classes.loadingContainer}>
              <span
                className={`material-symbols-outlined ${classes.loadingIcon}`}
              >
                progress_activity
              </span>
            </div>
          ) : sortedReposts.length > 0 ? (
            sortedReposts
              .filter((user) => {
                const fullName =
                  `${user.firstName} ${user.lastName}`.toLowerCase();
                return fullName.includes(searchTerm.toLowerCase());
              })
              .map(({ userId, ...user }) => (
                <div key={userId} className={classes.repostItem}>
                  <div className={classes.userContainer}>
                    <img
                      onClick={() => handleNavigate(userId)}
                      src={user.profileImage}
                      alt={`${user.firstName} ${user.lastName}`}
                      className={classes.profileImage}
                    />
                  </div>
                  <div className={classes.userInfo}>
                    <h3 className={classes.name}>
                      <div className={classes.nameWrapper}>
                        <span
                          className={classes.nameText}
                          onClick={() => handleNavigate(userId)}
                        >
                          {user.firstName} {user.lastName}
                        </span>
                        <span className={classes.connectionType}>
                          •{" "}
                          {userId === currentUserId
                            ? "You"
                            : user.connectionType || "Connection"}
                        </span>
                      </div>
                    </h3>
                    <p
                      className={`${classes.bio} ${!user.bio && classes.noBio}`}
                    >
                      {user.bio || user.username}
                    </p>
                    <p className={classes.repostTime}>
                      {formatTimeDifference(user.repostedAt)}
                    </p>
                  </div>
                </div>
              ))
          ) : (
            <p className={classes.default}>No reposts found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default RepostsModal;
