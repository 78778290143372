import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";
import Home from "./pages/Home/Home";
import Careers from "./pages/Careers/Careers";
import SignUp from "./pages/Onboarding/SignUp/SignUp";
import InitialProfileSetup from "./pages/Onboarding/SignUp/InitialProfileSetup";
import InitialLocationSetup from "./pages/Onboarding/SignUp/InitialLocationSetup";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import PrivateRoute from "./routes/PrivateRoute";
import Profile from "./pages/Main/Profile/Profile";
import { firestore } from "./firebase";
import { doc, getDoc } from "firebase/firestore";
import "./App.css";
import LogIn from "./pages/Onboarding/LogIn/LogIn";
import ForgotPassword from "./pages/Onboarding/LogIn/ForgotPassword";
import Dashboard from "./pages/Main/Dashboard/Dashboard";
import Notifications from "./pages/Main/Notifications/Notifications";
import Messages from "./pages/Main/Messages/Messages";
import People from "./pages/Main/People/People";
import Forums from "./pages/Main/Forums/Forums";
import Events from "./pages/Main/Events/Events";
import Settings from "./pages/Main/Settings/Settings";
import UserProfile from "./pages/Main/Profile/UserProfile";
import { NotificationProvider } from "./contexts/NotificationContext";
import WaitlistCount from "./WaitlistCount"; // Adjust the path as needed
import SinglePost from "./pages/Posts/SinglePost";
import AllPosts from "./pages/Posts/AllPosts";
import SingleRepost from "./pages/Reposts/SingleRepost";
import AllReposts from "./pages/Reposts/AllReposts";

import SingleReaction from "./pages/Reactions/SingleReaction";
import AllReactions from "./pages/Reactions/AllReactions";
import SingleComment from "./pages/Comments/SingleComment";
import AllComments from "./pages/Comments/AllComments";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log("Error caught by boundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

function SomeComponent() {
  return (
    <div>
      {/* Other component content */}
      <WaitlistCount />
    </div>
  );
}
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const useUserProfile = (uid) => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (uid) {
      const fetchProfile = async () => {
        try {
          const userDocRef = doc(firestore, "users", uid);
          const userDocSnap = await getDoc(userDocRef);
          if (userDocSnap.exists()) {
            setProfile(userDocSnap.data());
          } else {
            setProfile(null);
          }
        } catch (error) {
        } finally {
          setLoading(false);
        }
      };

      fetchProfile();
    } else {
      setLoading(false);
    }
  }, [uid]);

  return { profile, loading };
};

const RedirectBasedOnProfile = () => {
  const { currentUser } = useAuth();
  const { profile, loading } = useUserProfile(currentUser?.uid);
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    if (
      profile &&
      profile.firstName &&
      profile.lastName &&
      !profile.country &&
      !profile.city
    ) {
      setCurrentStep(2);
    }
  }, [profile]);

  const handleNextStep = () => {
    setCurrentStep(2);
  };

  if (loading) return null;

  if (!currentUser) {
    return <Navigate to="/signup" />;
  }

  if (
    profile &&
    profile.firstName &&
    profile.lastName &&
    profile.country &&
    profile.city
  ) {
    return <Navigate to="/profile" />;
  }

  return currentStep === 1 ? (
    <InitialProfileSetup onNext={handleNextStep} />
  ) : (
    <InitialLocationSetup />
  );
};

const RedirectIfIncompleteProfile = ({ children }) => {
  const { currentUser } = useAuth();
  const { profile, loading } = useUserProfile(currentUser?.uid);

  if (loading) return null;

  if (!currentUser) {
    return <Navigate to="/signup" />;
  }

  if (
    !profile ||
    !profile.firstName ||
    !profile.lastName ||
    !profile.country ||
    !profile.city
  ) {
    return <Navigate to="/onboarding" />;
  }

  return children;
};

const NotificationWrapper = ({ children }) => (
  <NotificationProvider>{children}</NotificationProvider>
);

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<LogIn />} />
            <Route path="/reset-password" element={<ForgotPassword />} />

            <Route path="/profile" element={<PrivateRoute />}>
              <Route
                path="/profile"
                element={
                  <RedirectIfIncompleteProfile>
                    <NotificationWrapper>
                      <Profile />
                    </NotificationWrapper>
                  </RedirectIfIncompleteProfile>
                }
              />
            </Route>
            <Route path="/onboarding" element={<PrivateRoute />}>
              <Route index element={<RedirectBasedOnProfile />} />
            </Route>
            <Route path="/dashboard" element={<PrivateRoute />}>
              <Route
                path="/dashboard"
                element={
                  <NotificationWrapper>
                    <ErrorBoundary>
                      <Dashboard />
                    </ErrorBoundary>
                  </NotificationWrapper>
                }
              />
            </Route>
            <Route path="/notifications" element={<PrivateRoute />}>
              <Route
                path="/notifications"
                element={
                  <NotificationWrapper>
                    <Notifications />
                  </NotificationWrapper>
                }
              />
            </Route>
            <Route path="/messages" element={<PrivateRoute />}>
              <Route
                path="/messages"
                element={
                  <NotificationWrapper>
                    <Messages />
                  </NotificationWrapper>
                }
              />
            </Route>
            <Route path="/people" element={<PrivateRoute />}>
              <Route
                path="/people"
                element={
                  <NotificationWrapper>
                    <People />
                  </NotificationWrapper>
                }
              />
            </Route>
            <Route path="/forums" element={<PrivateRoute />}>
              <Route
                path="/forums"
                element={
                  <NotificationWrapper>
                    <Forums />
                  </NotificationWrapper>
                }
              />
            </Route>
            <Route path="/events" element={<PrivateRoute />}>
              <Route
                path="/events"
                element={
                  <NotificationWrapper>
                    <Events />
                  </NotificationWrapper>
                }
              />
            </Route>
            <Route path="/settings" element={<PrivateRoute />}>
              <Route
                path="/settings"
                element={
                  <NotificationWrapper>
                    <Settings />
                  </NotificationWrapper>
                }
              />
            </Route>
            <Route path="/post/:postId" element={<PrivateRoute />}>
              <Route
                path="/post/:postId"
                element={
                  <NotificationWrapper>
                    <SinglePost />
                  </NotificationWrapper>
                }
              />
            </Route>
            <Route path="/all-posts/:urlSlug" element={<PrivateRoute />}>
              <Route
                path="/all-posts/:urlSlug"
                element={
                  <NotificationWrapper>
                    <AllPosts />
                  </NotificationWrapper>
                }
              />
            </Route>
            <Route path="/repost/:repostId" element={<PrivateRoute />}>
              <Route
                path="/repost/:repostId"
                element={
                  <NotificationWrapper>
                    <SingleRepost />
                  </NotificationWrapper>
                }
              />
            </Route>
            <Route path="/all-reposts/:urlSlug" element={<PrivateRoute />}>
              <Route
                path="/all-reposts/:urlSlug"
                element={
                  <NotificationWrapper>
                    <AllReposts />
                  </NotificationWrapper>
                }
              />
            </Route>
            <Route path="/reaction/:reactionId" element={<PrivateRoute />}>
              <Route
                path="/reaction/:reactionId"
                element={
                  <NotificationWrapper>
                    <SingleReaction />
                  </NotificationWrapper>
                }
              />
            </Route>
            <Route path="/all-reactions/:urlSlug" element={<PrivateRoute />}>
              <Route
                path="/all-reactions/:urlSlug"
                element={
                  <NotificationWrapper>
                    <AllReactions />
                  </NotificationWrapper>
                }
              />
            </Route>
            <Route path="/comment/:postId" element={<PrivateRoute />}>
              <Route
                path="/comment/:postId"
                element={
                  <NotificationWrapper>
                    <SingleComment />
                  </NotificationWrapper>
                }
              />
            </Route>
            <Route path="/all-comments/:urlSlug" element={<PrivateRoute />}>
              <Route
                path="/all-comments/:urlSlug"
                element={
                  <NotificationWrapper>
                    <AllComments />
                  </NotificationWrapper>
                }
              />
            </Route>
            <Route path="/waitlist1000" element={<WaitlistCount />}>
              <Route path="/waitlist1000" element={<WaitlistCount />} />
            </Route>
            <Route path="/:urlSlug" element={<PrivateRoute />}>
              <Route
                path="/:urlSlug"
                element={
                  <RedirectIfIncompleteProfile>
                    <NotificationWrapper>
                      <UserProfile />
                    </NotificationWrapper>
                  </RedirectIfIncompleteProfile>
                }
              />
            </Route>
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
