import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { firestore } from "../../firebase";
import classes from "./ProfileModal.module.css";
import CustomDropdown from "../Dropdowns/CustomDropdown";
import imageCompression from "browser-image-compression";

import { updateUserUrlSlug } from "../../utils/urlSlugUtils";

const config = {
  cUrl: "https://api.countrystatecity.in/v1",
  ckey: "clpLNnZGdE9JRzNXODdjdmVLUmtjcks2aDM4d1BiYmdPSjNoNGY4UQ==",
};

const ProfileModal = ({ onClose, currentUser, onProfileUpdate }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [bio, setBio] = useState("");
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [countryInputValue, setCountryInputValue] = useState("");
  const [cityInputValue, setCityInputValue] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [currentProfileImageUrl, setCurrentProfileImageUrl] = useState(null);
  const [initialData, setInitialData] = useState({});
  const [isChanged, setIsChanged] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [link, setLink] = useState("");
  const [linkText, setLinkText] = useState("");
  const [isValidUrl, setIsValidUrl] = useState(false);
  const [mouseDownOnBackdrop, setMouseDownOnBackdrop] = useState(false);
  const cityCache = useMemo(() => ({}), []);
  const formRef = useRef(null);
  const bioTextareaRef = useRef(null);

  const handleCloseTouch = (e, isSave = false) => {
    e.preventDefault();
    onClose(isSave);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      try {
        const userDocRef = doc(firestore, "users", currentUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setInitialData(userData);
          setFirstName(userData.firstName || "");
          setLastName(userData.lastName || "");
          setBio(userData.bio || "");
          setCurrentProfileImageUrl(userData.profileImage || "");
          setLink(userData.link || "");
          setLinkText(userData.linkText || "");
          setIsValidUrl(validateUrl(userData.link || ""));
          if (userData.country && userData.city) {
            setSelectedCountry({
              name: userData.country,
              iso2: userData.countryISO2,
            });
            setCountryInputValue(userData.country);
            setSelectedCity({ name: userData.city });
            setCityInputValue(userData.city);
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        setError("Failed to load user data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    const loadCountries = async () => {
      try {
        const response = await fetch(`${config.cUrl}/countries`, {
          headers: { "X-CSCAPI-KEY": config.ckey },
        });
        const data = await response.json();
        setCountries(Array.isArray(data) ? data : []);
      } catch (error) {
        setCountries([]);
      }
    };

    fetchUserData();
    loadCountries();
  }, [currentUser.uid]);

  const loadCities = useCallback(async () => {
    if (!selectedCountry) return;
    try {
      const response = await fetch(
        `${config.cUrl}/countries/${selectedCountry.iso2}/cities`,
        {
          headers: { "X-CSCAPI-KEY": config.ckey },
        }
      );
      const data = await response.json();
      cityCache[selectedCountry.iso2] = Array.isArray(data) ? data : [];
      setCities(cityCache[selectedCountry.iso2]);
    } catch (error) {
      setCities([]);
    }
  }, [selectedCountry, cityCache]);

  useEffect(() => {
    if (selectedCountry) {
      if (cityCache[selectedCountry.iso2]) {
        setCities(cityCache[selectedCountry.iso2]);
      } else {
        loadCities();
      }
    }
  }, [selectedCountry, cityCache, loadCities]);

  const handleFirstNameChange = (e) => {
    if (e.target.value.length <= 50) {
      setFirstName(e.target.value);
    }
  };

  const handleLastNameChange = (e) => {
    if (e.target.value.length <= 50) {
      setLastName(e.target.value);
    }
  };

  const handleBioChange = (e) => {
    if (e.target.value.length <= 250) {
      setBio(e.target.value);
    }
  };

  const handleProfileImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        // Compression options
        const options = {
          maxSizeMB: 8, // Max file size 8MB
          maxWidthOrHeight: 400, // Max width/height 400 pixels
          useWebWorker: true,
        };

        const compressedFile = await imageCompression(file, options);

        // Create a preview
        const reader = new FileReader();
        reader.onload = () => {
          setCurrentProfileImageUrl(reader.result);
        };
        reader.readAsDataURL(compressedFile);

        setProfileImage(compressedFile);
        e.target.value = "";
      } catch (error) {
        console.error("Error compressing image:", error);
        setError("Failed to process the image. Please try again.");
      }
    }
  };

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
    setCountryInputValue(value ? value.name : "");
    setSelectedCity(null);
    setCities([]);
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
    setCityInputValue(value ? value.name : "");
  };

  const validateUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const handleLinkChange = (e) => {
    const newLink = e.target.value;
    if (newLink.length <= 250) {
      setLink(newLink);
      if (!newLink) {
        setLinkText("");
      }
      setIsValidUrl(validateUrl(newLink));
    }
  };

  const handleLinkTextChange = (e) => {
    if (e.target.value.length <= 50) {
      setLinkText(e.target.value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!firstName || !lastName || !selectedCountry || !selectedCity) {
      setError("Please enter all details.");
      return;
    }

    setLoading(true);
    setError("");

    try {
      const userDocRef = doc(firestore, "users", currentUser.uid);
      const userDoc = await getDoc(userDocRef);

      let profileImageUrl = currentProfileImageUrl || "";
      if (profileImage) {
        const storage = getStorage();
        const profileImageRef = ref(
          storage,
          `profileImages/${currentUser.uid}/profile.jpg`
        );
        await uploadBytes(profileImageRef, profileImage);
        profileImageUrl = await getDownloadURL(profileImageRef);
      }

      if (!profileImageUrl) {
        profileImageUrl = "";
      }

      const newSlug = await updateUserUrlSlug(
        currentUser.uid,
        firstName,
        lastName
      );

      const updatedProfile = {
        firstName,
        lastName,
        bio,
        country: selectedCountry.name,
        countryISO2: selectedCountry.iso2,
        city: selectedCity.name,
        profileImage: profileImageUrl,
        link,
        linkText: link ? linkText : "",
        urlSlug: newSlug,
      };

      const newProfileData = { ...userDoc.data(), ...updatedProfile };

      await updateDoc(userDocRef, newProfileData);

      onProfileUpdate(newProfileData);
      // Don't call onClose() here
    } catch (error) {
      setError("Failed to update profile. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const scrollY = window.scrollY;

    // Apply the scroll position to the modal backdrop
    const backdrop = document.querySelector(`.${classes.modalBackdrop}`);
    if (backdrop) {
      backdrop.style.top = `${scrollY}px`;
    }

    // Prevent body scrolling
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    document.body.style.width = "100%";

    return () => {
      // Restore body scrolling
      document.body.style.position = "";
      document.body.style.top = "";
      document.body.style.width = "";

      // Don't scroll here, let Profile.js handle it
    };
  }, []);

  useEffect(() => {
    const dataChanged =
      (firstName !== initialData.firstName && firstName !== "") ||
      (lastName !== initialData.lastName && lastName !== "") ||
      bio !== initialData.bio ||
      (selectedCountry && selectedCountry.name) !== initialData.country ||
      (selectedCity && selectedCity.name) !== initialData.city ||
      link !== initialData.link ||
      (link && linkText !== initialData.linkText) ||
      profileImage;

    setIsChanged(dataChanged);
  }, [
    firstName,
    lastName,
    bio,
    selectedCountry,
    selectedCity,
    link,
    linkText,
    profileImage,
    initialData,
  ]);

  useEffect(() => {
    setIsFormValid(
      firstName.length > 0 &&
        lastName.length > 0 &&
        selectedCountry &&
        selectedCity &&
        (link.length === 0 || (link.length > 0 && isValidUrl))
    );
  }, [firstName, lastName, selectedCountry, selectedCity, link, isValidUrl]);

  const handleSaveButtonClick = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(
        new Event("submit", { bubbles: true, cancelable: true })
      );
      // Only close the modal if there are no errors
      if (!error) {
        onClose(true);
      }
    }
  };

  useEffect(() => {
    const textarea = bioTextareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [bio]);

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  const handleBackdropMouseDown = (e) => {
    if (e.target === e.currentTarget) {
      setMouseDownOnBackdrop(true);
    }
  };

  const handleBackdropMouseUp = (e) => {
    if (e.target === e.currentTarget && mouseDownOnBackdrop) {
      onClose(false); // Pass false for regular close
    }
    setMouseDownOnBackdrop(false);
  };

  const handleProfileImageClick = (e) => {
    e.preventDefault();
    const input = document.getElementById("profileImage");
    if (input) {
      input.click();
    }
  };

  return (
    <div
      className={classes.modalBackdrop}
      onMouseDown={handleBackdropMouseDown}
      onMouseUp={handleBackdropMouseUp}
    >
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            Edit profile
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              fill="none"
              viewBox="0 0 24 24"
              onClick={() => onClose(false)} // Pass false for regular close
              onTouchStart={(e) => handleCloseTouch(e, false)} // Pass false for regular close
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>
        </div>
        <form
          className={classes.interactions}
          onSubmit={handleSubmit}
          ref={formRef}
        >
          {loading ? (
            <div className={classes.loadingContainer}>
              <span
                className={`material-symbols-outlined ${classes.loadingIcon}`}
              >
                progress_activity
              </span>
            </div>
          ) : (
            <>
              <div className={classes.imageContainer}>
                <div className={classes.profileImage}>
                  <img
                    src={currentProfileImageUrl || "path_to_default_image"}
                    alt="Profile"
                    onClick={handleProfileImageClick}
                    onTouchStart={(e) => {
                      e.preventDefault();
                      handleProfileImageClick(e);
                    }}
                  />
                  <label htmlFor="profileImage" className={classes.uploadIcon}>
                    <svg
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="48"
                      height="48"
                      fill="whitesmoke"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.5 4.586A2 2 0 0 1 8.914 4h6.172a2 2 0 0 1 1.414.586L17.914 6H19a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h1.086L7.5 4.586ZM10 12a2 2 0 1 1 4 0 2 2 0 0 1-4 0Zm2-4a4 4 0 1 0 0 8 4 4 0 0 0 0-8Z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </label>
                  <input
                    type="file"
                    id="profileImage"
                    accept="image/*"
                    onChange={handleProfileImageChange}
                    className={classes.profileButton}
                  />
                </div>
              </div>

              <label htmlFor="firstName" className={classes.label}>
                First name*
              </label>
              <input
                id="firstName"
                name="firstName"
                type="text"
                placeholder="Ex: Brian"
                className={classes.userInput}
                value={firstName}
                onChange={handleFirstNameChange}
                disabled={loading}
              />
              <div className={classes.charCount}>{firstName.length}/50</div>
              <label htmlFor="lastName" className={classes.label}>
                Last name*
              </label>
              <input
                id="lastName"
                name="lastName"
                type="text"
                placeholder="Ex: Chesky"
                className={classes.userInput}
                value={lastName}
                onChange={handleLastNameChange}
                disabled={loading}
              />
              <div className={classes.charCount}>{lastName.length}/50</div>
              <label htmlFor="bio" className={classes.label}>
                Headline
              </label>
              <textarea
                id="bio"
                ref={bioTextareaRef}
                value={bio}
                onChange={handleBioChange}
                className={classes.textArea}
                placeholder="Ex: Co-founder, CEO at AirBedAndBreakfast"
              />
              <div className={classes.charCount}>{bio.length}/250</div>
              <label htmlFor="country" className={classes.label}>
                Country*
              </label>
              <CustomDropdown
                id="country"
                options={countries}
                selectedValue={selectedCountry}
                onChange={handleCountryChange}
                inputValue={countryInputValue}
                setInputValue={setCountryInputValue}
                disabled={loading}
                placeholder="Ex: United Kingdom"
              />
              <label
                htmlFor="city"
                className={`${classes.label} ${
                  !selectedCountry || loading ? classes.disabled : ""
                }`}
              >
                City*
              </label>
              <CustomDropdown
                id="city"
                options={cities}
                selectedValue={selectedCity}
                onChange={handleCityChange}
                inputValue={cityInputValue}
                setInputValue={setCityInputValue}
                disabled={!selectedCountry || loading}
                placeholder="Ex: London"
              />
              <label htmlFor="link" className={classes.label}>
                Link
              </label>
              <input
                id="link"
                name="link"
                type="url"
                placeholder="Ex: https://example.com"
                className={classes.userInput}
                value={link}
                onChange={handleLinkChange}
                disabled={loading}
              />
              <div className={classes.charCount}>{link.length}/250</div>
              <label
                htmlFor="linkText"
                className={`${classes.linkTextLabel} ${
                  !isValidUrl ? classes.disabledInput : ""
                }`}
              >
                Link text
              </label>
              <input
                id="linkText"
                name="linkText"
                type="text"
                placeholder="Ex: example.com"
                className={`${classes.userInput} ${
                  !isValidUrl ? classes.disabledInput : ""
                }`}
                value={linkText}
                onChange={handleLinkTextChange}
                disabled={loading || !isValidUrl}
                style={{ opacity: isValidUrl ? 1 : 0.5 }}
              />

              <div
                className={`${classes.smallPrint} ${
                  !isValidUrl ? classes.disabledInput : ""
                }`}
              >
                <p>Customise how your link will appear (optional).</p>
                <span className={classes.charCount}>{linkText.length}/50</span>
              </div>
            </>
          )}
        </form>

        {error && <p className={classes.error}>{error}</p>}

        <div className={classes.cardFooter}>
          <button
            type="button"
            className={`${classes.button} ${loading ? classes.loading : ""}`}
            disabled={!isChanged || !isFormValid || loading}
            onClick={() => {
              handleSaveButtonClick();
              // Only close the modal if there are no errors and the form is valid
              if (!error && isFormValid) {
                onClose(true);
              }
            }}
          >
            {loading ? (
              <span
                className={`material-symbols-outlined ${classes.loadingIcon}`}
              >
                progress_activity
              </span>
            ) : (
              "Save"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileModal;
