import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import OnboardingNavbar from "../../../components/Navbars/OnboardingNavbar";
import { useAuth } from "../../../contexts/AuthContext";
import { firestore } from "../../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import CustomDropdown from "../../../components/Dropdowns/CustomDropdown";
import classes from "./InitialLocationSetup.module.css";

const config = {
  cUrl: "https://api.countrystatecity.in/v1",
  ckey: "clpLNnZGdE9JRzNXODdjdmVLUmtjcks2aDM4d1BiYmdPSjNoNGY4UQ==",
};

const InitialLocationSetup = () => {
  const { currentUser } = useAuth();
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [countryInputValue, setCountryInputValue] = useState("");
  const [cityInputValue, setCityInputValue] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [countryLoading, setCountryLoading] = useState(true);
  const [cityLoading, setCityLoading] = useState(false);

  const cityCache = useMemo(() => ({}), []);

  useEffect(() => {
    const loadCountries = async () => {
      setCountryLoading(true);
      try {
        const response = await fetch(`${config.cUrl}/countries`, {
          headers: { "X-CSCAPI-KEY": config.ckey },
        });
        const data = await response.json();
        setCountries(data);
      } catch (error) {
      } finally {
        setCountryLoading(false);
      }
    };

    loadCountries();
  }, []);

  const loadCities = useCallback(async () => {
    setCityLoading(true);
    try {
      const response = await fetch(
        `${config.cUrl}/countries/${selectedCountry.iso2}/cities`,
        {
          headers: { "X-CSCAPI-KEY": config.ckey },
        }
      );
      const data = await response.json();
      cityCache[selectedCountry.iso2] = data;
      setCities(data);
    } catch (error) {
    } finally {
      setCityLoading(false);
    }
  }, [selectedCountry, cityCache]);

  useEffect(() => {
    if (selectedCountry) {
      if (cityCache[selectedCountry.iso2]) {
        setCities(cityCache[selectedCountry.iso2]);
      } else {
        loadCities();
      }
    }
  }, [selectedCountry, cityCache, loadCities]);

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
    setCountryInputValue(value ? value.name : "");
    setSelectedCity(null);
    setCities([]);
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
    setCityInputValue(value ? value.name : "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedCountry || !selectedCity) {
      setError("Please select your country and city.");
      return;
    }

    setLoading(true);
    try {
      const userDocRef = doc(firestore, "users", currentUser.uid);
      await updateDoc(userDocRef, {
        country: selectedCountry.name,
        countryISO2: selectedCountry.iso2,
        city: selectedCity.name,
      });

      navigate("/profile");
    } catch (error) {
      setError("Failed to update location. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const isSubmitDisabled =
    loading ||
    !selectedCountry ||
    !selectedCity ||
    countryInputValue !== selectedCountry?.name ||
    cityInputValue !== selectedCity?.name;

  return (
    <div className={classes.page}>
      <OnboardingNavbar />
      <div className={classes.content}>
        <div className={classes.formContainer}>
          <div className={classes.formContent}>
            <div className={classes.formAbout}>
              <p className={classes.primaryAbout}>Where are you based?</p>
            </div>

            <form className={classes.interactions} onSubmit={handleSubmit}>
              <label htmlFor="country" className={classes.label}>
                Country*
              </label>
              <CustomDropdown
                id="country"
                options={countries}
                selectedValue={selectedCountry}
                onChange={handleCountryChange}
                inputValue={countryInputValue}
                setInputValue={setCountryInputValue}
                disabled={loading}
                placeholder="Ex: United Kingdom"
                loading={countryLoading}
              />

              <label
                htmlFor="city"
                className={`${classes.label} ${
                  !selectedCountry || loading ? classes.disabled : ""
                }`}
              >
                City*
              </label>
              <CustomDropdown
                id="city"
                options={cities}
                selectedValue={selectedCity}
                onChange={handleCityChange}
                inputValue={cityInputValue}
                setInputValue={setCityInputValue}
                disabled={!selectedCountry || loading}
                placeholder="Ex: London"
                loading={cityLoading}
              />

              <button
                type="submit"
                className={`${classes.button} ${
                  loading ? classes.loading : ""
                }`}
                disabled={isSubmitDisabled}
              >
                {loading ? (
                  <span
                    className={`material-symbols-outlined ${classes.loadingIcon}`}
                  >
                    progress_activity
                  </span>
                ) : (
                  "Continue"
                )}
              </button>
            </form>

            {error && (
              <p className={classes.error}>
                <span
                  className={`material-symbols-outlined ${classes.errorIcon}`}
                >
                  error
                </span>
                {error}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InitialLocationSetup;
