import React, { useState } from "react";
import { useAuth } from "../../../contexts/AuthContext";
import OnboardingNavbar from "../../../components/Navbars/OnboardingNavbar";
import classes from "./ForgotPassword.module.css";
import { Link } from "react-router-dom";

function ForgotPassword() {
  const { resetPassword } = useAuth();
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [additionalMessage, setAdditionalMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleEmailChange = (e) => {
    const emailValue = e.target.value.toLowerCase();
    setEmail(emailValue);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(emailValue));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isEmailValid) {
      setError("Please enter a valid email to reset your password.");
      return;
    }

    setLoading(true);
    try {
      await resetPassword(email);
      setMessage(
        <>
          <div className={classes.successMessage}>
            <svg
              className={classes.errorIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                fill-rule="evenodd"
                d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
                clip-rule="evenodd"
              />
            </svg>
            We've sent you a link to reset your password.
          </div>
        </>
      );
      setAdditionalMessage(
        "If you don't see the email in your inbox, check your spam folder. If it's not there, the email address may not be confirmed, or it may not match an existing Foundry account."
      );
      setError("");
    } catch (error) {
      setError("Sorry, your email or password is incorrect.");
      setMessage("");
      setAdditionalMessage("");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.page}>
      <OnboardingNavbar />
      <div className={classes.content}>
        <div className={classes.formContainer}>
          <div className={classes.formContent}>
            <div className={classes.formAbout}>
              <p className={classes.primaryAbout}>Reset your password</p>
            </div>
            <form className={classes.interactions} onSubmit={handleSubmit}>
              <label htmlFor="email" className={classes.label}>
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                placeholder="Ex: joe@example.com"
                className={classes.email}
                value={email}
                onChange={handleEmailChange}
                disabled={loading}
              />
              <div className={classes.smallPrint}>
                <p>
                  {message ||
                    "We'll send you a password reset link to this email."}
                </p>
              </div>
              <button
                type="submit"
                className={`${classes.button} ${
                  loading ? classes.loading : ""
                }`}
                disabled={!isEmailValid || loading}
              >
                {loading ? (
                  <span
                    className={`material-symbols-outlined ${classes.loadingIcon}`}
                  >
                    progress_activity
                  </span>
                ) : (
                  "Send"
                )}
              </button>
              {/* <Link className={classes.back} to="/login">
                Back
              </Link> */}

              {error && (
                <p className={classes.error}>
                  <span
                    className={`material-symbols-outlined ${classes.errorIcon}`}
                  >
                    error
                  </span>
                  {error}
                </p>
              )}
              {/* {additionalMessage && (
                <p className={classes.smallPrint}>{additionalMessage}</p>
              )} */}
              <div className={classes.backLink}></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
