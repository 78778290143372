import { Link } from "react-router-dom";
import icon from "../../assets/images/icon.png";
import classes from "./Footer.module.css";
import rounded from "../../assets/icons/rounded.png";

function Footer() {
  return (
    <footer>
      <div className={classes.topFooter}>
        <div className={classes.footerIcon}>
          <Link to="/">
            <img src={icon} alt="" className={classes.icon} />
          </Link>
          <Link to="/">
            <div className={classes.logo}>Foundry</div>
          </Link>
        </div>
        <div className={classes.bottomFooterMobile}>
          <div className={classes.companyMenu}>
            <p>Company</p>
            <Link to="/">About</Link>
            <Link to="/careers">Careers at Foundry</Link>
          </div>
          <div className={classes.companyMenu}>
            <p>Social</p>
            <a
              href="https://uk.linkedin.com/company/foundrylabsltd"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn
            </a>
          </div>
        </div>

        <div className={classes.socialMenu}>
          <p>Illustrations</p>
          <a href="https://popsy.co/" target="_blank" rel="noopener noreferrer">
            popsy.co
          </a>
        </div>
      </div>
      <div className={classes.bottomFooter}>
        <p className={classes.copyright}>© 2024 Foundry Labs Ltd.</p>
      </div>
    </footer>
  );
}
export default Footer;
