import React, { useState, useRef, useEffect } from "react";
import monthsList from "../../utils/monthsList";
import classes from "./MonthDropdown.module.css";

const MonthDropdown = ({
  label,
  selectedMonth,
  onMonthChange,
  disabled,
  maxMonth,
  minMonth,
  placeholder,
  isEndDate,
  startYear,
  endYear,
  currentYear,
  currentMonth,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const dropdownRef = useRef(null);

  const getFilteredMonths = () => {
    let start = (minMonth || 1) - 1;
    let end = maxMonth || 12;

    if (isEndDate) {
      if (
        parseInt(startYear) === currentYear ||
        parseInt(endYear) === currentYear
      ) {
        end = Math.min(end, currentMonth);
      }
    }

    return monthsList.slice(start, end);
  };

  const filteredMonths = getFilteredMonths();

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleSelect = (month) => {
    onMonthChange(monthsList.indexOf(month) + 1);
    setIsOpen(false);
    setHighlightedIndex(-1);
  };

  const handleKeyDown = (e) => {
    if (!isOpen) return;

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        setHighlightedIndex((prevIndex) =>
          Math.min(prevIndex + 1, filteredMonths.length - 1)
        );
        break;
      case "ArrowUp":
        e.preventDefault();
        setHighlightedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        break;
      case "Enter":
        e.preventDefault();
        if (highlightedIndex >= 0) {
          handleSelect(filteredMonths[highlightedIndex]);
        }
        break;
      case "Escape":
        setIsOpen(false);
        break;
      default:
        break;
    }
  };

  return (
    <div className={classes.customDropdown} ref={dropdownRef}>
      <label className={classes.label}>{label}</label>
      <div className={classes.dropdownContainer}>
        <div className={classes.inputWrapper}>
          <div
            className={`${classes.dropdownHeader} ${
              disabled ? classes.disabled : ""
            } ${isOpen ? classes.active : ""}`}
            onClick={() => !disabled && toggleDropdown()}
            onKeyDown={handleKeyDown}
            tabIndex={0}
          >
            <span
              className={
                selectedMonth ? classes.selectedText : classes.placeholderText
              }
            >
              {selectedMonth ? monthsList[selectedMonth - 1] : placeholder}
            </span>
          </div>
          <span className={classes.dropdownIcon}>
            {isOpen ? (
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m16 14-4-4-4 4"
                />
              </svg>
            ) : (
              <svg
                className="w-6 h-6 text-gray-800 dark:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="gray"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.75"
                  d="m8 10 4 4 4-4"
                />
              </svg>
            )}
          </span>
        </div>
        {isOpen && !disabled && (
          <div className={classes.dropdownListContainer}>
            <ul className={classes.dropdownList}>
              {filteredMonths.map((month, index) => (
                <li
                  key={month}
                  className={`${classes.dropdownListItem} ${
                    index === highlightedIndex ? classes.highlightedItem : ""
                  }`}
                  onClick={() => handleSelect(month)}
                  onMouseEnter={() => setHighlightedIndex(index)}
                >
                  {month}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default MonthDropdown;
