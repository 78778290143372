import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { firestore } from "../../../firebase";
import OnboardingNavbar from "../../../components/Navbars/OnboardingNavbar";
import googleIcon from "../../../assets/images/googleIcon.svg";
import classes from "./LogIn.module.css";

function LogIn() {
  const navigate = useNavigate();
  const { signIn, signInWithGoogle, currentUser } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);

  useEffect(() => {
    if (currentUser) {
      const checkFirestoreFields = async () => {
        try {
          const userDocRef = doc(firestore, "users", currentUser.uid);
          const userDocSnap = await getDoc(userDocRef);
          const userData = userDocSnap.data();

          if (
            userData &&
            userData.firstName &&
            userData.lastName &&
            userData.country &&
            userData.city
          ) {
            navigate("/profile");
          } else {
            navigate("/onboarding");
          }
        } catch (error) {}
      };

      checkFirestoreFields();
    }
  }, [currentUser, navigate]);

  const handleEmailChange = (e) => {
    const emailValue = e.target.value.toLowerCase();
    setEmail(emailValue);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValid(emailRegex.test(emailValue));
  };

  const handlePasswordChange = (e) => {
    const passwordValue = e.target.value;
    setPassword(passwordValue);
    setIsPasswordValid(passwordValue.length >= 6);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isEmailValid && isPasswordValid) {
      setLoading(true);
      try {
        const userCredential = await signIn(email, password);
        const user = userCredential.user;

        const userDocRef = doc(firestore, "users", user.uid);
        const userDocSnap = await getDoc(userDocRef);
        const userData = userDocSnap.data();

        if (!userData.firstName || !userData.lastName) {
          navigate("/onboarding");
        } else if (!userData.country || !userData.city) {
          navigate("/onboarding");
        } else {
          navigate("/profile");
        }
      } catch (error) {
        setError("Sorry, your email or password is incorrect.");
      } finally {
        setLoading(false);
      }
    } else {
      setError("Sorry, your email or password is incorrect.");
    }
  };

  const handleGoogleSignIn = async () => {
    setGoogleLoading(true);
    try {
      const userCredential = await signInWithGoogle();
      const user = userCredential.user;

      const userDocRef = doc(firestore, "users", user.uid);
      const userDocSnap = await getDoc(userDocRef);
      const userData = userDocSnap.data();

      if (
        userData &&
        userData.firstName &&
        userData.lastName &&
        userData.country &&
        userData.city
      ) {
        navigate("/profile");
      } else if (userData && (userData.firstName || userData.lastName)) {
        navigate("/onboarding");
      } else {
        await setDoc(doc(firestore, "users", user.uid), {
          email: user.email,
          firstName: "",
          lastName: "",
          country: "",
          city: "",
        });

        navigate("/onboarding");
      }
    } catch (error) {
      setError("Sorry, your email or password is incorrect.");
    } finally {
      setGoogleLoading(false);
    }
  };

  const isFormValid = isEmailValid && isPasswordValid;

  return (
    <div className={classes.page}>
      <OnboardingNavbar />
      <div className={classes.content}>
        <div className={classes.formContainer}>
          <div className={classes.formContent}>
            <div className={classes.formAbout}>
              <p className={classes.primaryAbout}>Make ideas happen</p>
            </div>
            {/* <div className={classes.interactions}>
              <button
                type="button"
                className={classes.altEmail}
                onClick={handleGoogleSignIn}
                disabled={googleLoading}
              >
                <img src={googleIcon} alt="" className={classes.googleIcon} />
                Continue with Google
              </button>
            </div> */}
            <form className={classes.interactions} onSubmit={handleSubmit}>
              <label htmlFor="email" className={classes.label}>
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                placeholder="Ex: joe@example.com"
                className={classes.email}
                value={email}
                onChange={handleEmailChange}
                disabled={loading}
              />
              <label htmlFor="password" className={classes.label}>
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                placeholder="Enter your password"
                className={classes.email}
                value={password}
                onChange={handlePasswordChange}
                autoComplete="new-password"
                disabled={loading}
              />
              <Link to="/reset-password" className={classes.forgotPassword}>
                Forgot password?
              </Link>
              <button
                type="submit"
                className={`${classes.button} ${
                  loading ? classes.loading : ""
                }`}
                disabled={!isFormValid || loading}
              >
                {loading ? (
                  <span
                    className={`material-symbols-outlined ${classes.loadingIcon}`}
                  >
                    progress_activity
                  </span>
                ) : (
                  "Continue"
                )}
              </button>
              {error && (
                <p className={classes.smallPrint}>
                  <span
                    className={`material-symbols-outlined ${classes.errorIcon}`}
                  >
                    error
                  </span>
                  {error}
                </p>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogIn;
