import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { firestore } from "../../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import classes from "./MutualConnectionsModal.module.css";

const MutualConnectionsModal = ({ onClose, currentUserId, loggedInUserId }) => {
  const [mutualConnections, setMutualConnections] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [totalMutualConnections, setTotalMutualConnections] = useState(0);

  useEffect(() => {
    const fetchMutualConnections = async () => {
      try {
        const currentUserConnectionsRef = collection(
          firestore,
          `users/${loggedInUserId}/connections`
        );
        const profileUserConnectionsRef = collection(
          firestore,
          `users/${currentUserId}/connections`
        );

        const [currentUserConnectionsSnapshot, profileUserConnectionsSnapshot] =
          await Promise.all([
            getDocs(
              query(
                currentUserConnectionsRef,
                where("status", "==", "Connected")
              )
            ),
            getDocs(
              query(
                profileUserConnectionsRef,
                where("status", "==", "Connected")
              )
            ),
          ]);

        const currentUserConnections = new Set(
          currentUserConnectionsSnapshot.docs.map((doc) => doc.id)
        );
        const profileUserConnections = new Set(
          profileUserConnectionsSnapshot.docs.map((doc) => doc.id)
        );

        const mutualConnectionIds = [...currentUserConnections].filter((id) =>
          profileUserConnections.has(id)
        );

        const mutualConnectionsData = await Promise.all(
          mutualConnectionIds.map(async (userId) => {
            const userDoc = await getDoc(doc(firestore, "users", userId));
            return {
              id: userId,
              ...userDoc.data(),
              connectionType: "Direct",
            };
          })
        );

        setMutualConnections(mutualConnectionsData);
        setTotalMutualConnections(mutualConnectionsData.length); // Add this line
      } catch (error) {
        console.error("Error fetching mutual connections:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMutualConnections();
  }, [currentUserId, loggedInUserId]);

  useEffect(() => {
    document.body.classList.add(classes.bodyNoScroll);
    return () => {
      document.body.classList.remove(classes.bodyNoScroll);
    };
  }, []);

  const handleNavigate = async (userId) => {
    onClose();
    if (userId === loggedInUserId) {
      navigate("/profile");
    } else {
      try {
        const userDoc = await getDoc(doc(firestore, "users", userId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          navigate(`/${userData.urlSlug}`);
        } else {
          console.error("User not found");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredMutualConnections = useMemo(() => {
    return mutualConnections.filter((connection) => {
      const fullName =
        `${connection.firstName} ${connection.lastName}`.toLowerCase();
      return fullName.includes(searchTerm.toLowerCase());
    });
  }, [mutualConnections, searchTerm]);

  const handleCloseTouch = (e) => {
    e.preventDefault();
    onClose();
  };

  return (
    <div className={classes.modalBackdrop} onClick={onClose}>
      <div
        className={classes.modalContent}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.cardHeader}>
          <div className={classes.cardTitle}>
            {totalMutualConnections}{" "}
            {totalMutualConnections === 1
              ? "Mutual Connection"
              : "Mutual Connections"}
            <svg
              className={classes.closeIcon}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="none"
              viewBox="0 0 24 24"
              onClick={onClose}
              onTouchStart={handleCloseTouch}
            >
              <path
                stroke="gray"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18 17.94 6M18 18 6.06 6"
              />
            </svg>
          </div>

          <div className={classes.searchContainer}>
            <div className={classes.inputWrapper}>
              <input
                type="text"
                placeholder="Ex: Paul Graham"
                value={searchTerm}
                onChange={handleSearchChange}
                className={classes.userInput}
              />
              <svg
                className={classes.searchIcon}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="gray"
                  stroke-linecap="round"
                  stroke-width="2"
                  d="m21 21-3.5-3.5M17 10a7 7 0 1 1-14 0 7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
          </div>
        </div>
        <form className={classes.interactions}>
          <div className={classes.connectionsList}>
            {loading ? (
              <div className={classes.loadingIndicator}>
                <span
                  className={`material-symbols-outlined ${classes.loadingIcon}`}
                >
                  progress_activity
                </span>
              </div>
            ) : filteredMutualConnections.length > 0 ? (
              filteredMutualConnections.map((connection) => (
                <div key={connection.id} className={classes.connectionItem}>
                  <img
                    onClick={() => handleNavigate(connection.id)}
                    src={connection.profileImage}
                    alt={`${connection.firstName} ${connection.lastName}`}
                    className={classes.profileImage}
                  />
                  <div className={classes.connectionInfo}>
                    <h3 className={classes.name}>
                      <div className={classes.nameWrapper}>
                        <span
                          className={classes.nameText}
                          onClick={() => handleNavigate(connection.id)}
                        >
                          {connection.firstName} {connection.lastName}
                        </span>
                        <span className={classes.connectionType}>
                          • {connection.connectionType}
                        </span>
                      </div>
                    </h3>

                    <p className={classes.bio}>{connection.bio}</p>
                  </div>
                </div>
              ))
            ) : (
              <p className={classes.default}>No mutual connections found.</p>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default MutualConnectionsModal;
