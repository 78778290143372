import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { firestore, auth } from "../../firebase";
import RepostsModal from "../../components/Modals/RepostsModal";
import PostActionPopup from "./PostActionPopup";

import {
  collection,
  addDoc,
  increment,
  query,
  where,
  getDocs,
  orderBy,
  doc,
  writeBatch,
  getDoc,
  updateDoc,
  deleteField,
  deleteDoc,
  setDoc,
  serverTimestamp,
} from "firebase/firestore";
import MainNavbar from "../../components/Navbars/MainNavbar";
import MobileNavbar from "../../components/Navbars/MobileNavbar";
import classes from "./AllPosts.module.css";
import profileImage from "../../assets/icons/profileImage.jpg";
import { convertUrlsToLinks } from "../../utils/textUtils";
import EmojiPicker from "../../components/Dropdowns/EmojiPicker";
import ReactionsModal from "../../components/Modals/ReactionsModal";
import PostModal from "../../components/Modals/PostModal";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

function AllPosts() {
  const [isEditingComplete, setIsEditingComplete] = useState(false);
  const [postModalScrollPosition, setPostModalScrollPosition] = useState(0);
  const [isPostModalOpen, setIsPostModalOpen] = useState(false);

  const [showEditModal, setShowEditModal] = useState(false);
  const [editingPost, setEditingPost] = useState(null);
  const { urlSlug } = useParams();
  const [posts, setPosts] = useState([]);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 648);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserProfileImage, setCurrentUserProfileImage] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState({});
  const [showReactionsModal, setShowReactionsModal] = useState({});
  const [videoVolume, setVideoVolume] = useState({});
  const videoRefs = useRef({});
  const [expandedPosts, setExpandedPosts] = useState({});
  const [showDropdown, setShowDropdown] = useState({});
  const navigate = useNavigate();
  const storage = getStorage();
  const [showRepostsModal, setShowRepostsModal] = useState({});
  const [repostMessage, setRepostMessage] = useState(null);
  const [reactionsModalScrollPosition, setReactionsModalScrollPosition] =
    useState(0);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editCommentText, setEditCommentText] = useState("");
  const editTextareaRef = useRef(null);
  const [isReactionsModalOpen, setIsReactionsModalOpen] = useState(false);
  const [repostsModalScrollPosition, setRepostsModalScrollPosition] =
    useState(0);
  const [isRepostsModalOpen, setIsRepostsModalOpen] = useState(false);
  const [activePostReactions, setActivePostReactions] = useState(null);
  const [activePostReposts, setActivePostReposts] = useState(null);
  const [deleteMessage, setDeleteMessage] = useState(null);

  const [isSafari, setIsSafari] = useState(false);

  // New state variables for comments
  const [errorPopup, setErrorPopup] = useState(null);

  const [comments, setComments] = useState({});
  const [newComment, setNewComment] = useState({});
  const [replyComments, setReplyComments] = useState({});
  const [activeCommentId, setActiveCommentId] = useState({});
  const [visibleComments, setVisibleComments] = useState({});
  const [visibleReplies, setVisibleReplies] = useState({});
  const [expandedComments, setExpandedComments] = useState({});
  const [expandedReplies, setExpandedReplies] = useState({});
  const [showCommentEmojiPicker, setShowCommentEmojiPicker] = useState({});
  const [activeCommentForReaction, setActiveCommentForReaction] =
    useState(null);
  const [openCommentDropdown, setOpenCommentDropdown] = useState(null);
  const [showComments, setShowComments] = useState({});

  const [activeEmojiPicker, setActiveEmojiPicker] = useState(null);

  const commentInputRef = useRef({});
  const replyInputRef = useRef({});

  const hasInteractions = (post) => {
    return (
      (post.reactions && Object.keys(post.reactions).length > 0) ||
      (post.reposts && Object.keys(post.reposts).length > 0)
    );
  };

  const handleEditPost = (post) => {
    setPostModalScrollPosition(window.scrollY);
    setEditingPost(post);
    setShowEditModal(true);
    setIsPostModalOpen(true);
  };

  useEffect(() => {
    const checkSafari = () => {
      const isSafari =
        /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
        /apple/i.test(navigator.vendor);
      setIsSafari(isSafari);
      document.documentElement.style.setProperty(
        "--emoji-font-size",
        isSafari ? "0.75rem" : "0.875rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-margin-left",
        isSafari ? "-0.25rem" : "-0.25rem"
      );
      document.documentElement.style.setProperty(
        "--count-margin-left",
        isSafari ? "0.25rem" : "0.25rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-text-size",
        isSafari ? "0.75rem" : "0.875rem"
      );

      document.documentElement.style.setProperty(
        "--emoji-margin-bottom",
        isSafari ? "0rem" : "0rem"
      );
      document.documentElement.style.setProperty(
        "--emoji-margin-top",
        isSafari ? "0.125rem" : "0rem"
      );
    };
    checkSafari();
  }, []);

  const fetchRepostData = useCallback(async (reposts) => {
    const repostData = {};
    for (const [userId, timestamp] of Object.entries(reposts)) {
      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        repostData[userId] = {
          id: userId,
          firstName: userData.firstName,
          lastName: userData.lastName,
          profileImage: userData.profileImage || profileImage,
          timestamp: timestamp,
        };
      }
    }
    return repostData;
  }, []);

  const RepostPopup = ({ message, onClose, postId, isRepost }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="orangered"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm13.707-1.293a1 1 0 0 0-1.414-1.414L11 12.586l-1.793-1.793a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4-4Z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
          {isRepost && (
            <Link
              to={`/repost/${postId}`}
              state={{ fromRepostPopup: true }}
              className={classes.viewRepostLink}
            >
              View repost
            </Link>
          )}
        </div>
        <div onClick={onClose} className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  const ErrorPopup = ({ message, onClose }) => {
    return (
      <div className={classes.repostPopup}>
        <div className={classes.repostPopupContent}>
          <svg
            className={classes.tickIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="red"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm-1 13a1 1 0 1 1 2 0 1 1 0 0 1-2 0zm1-8a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V8a1 1 0 0 1 1-1z"
              clipRule="evenodd"
            />
          </svg>
          <p>{message}</p>
        </div>
        <div className={classes.repostCloseButton}>
          <svg
            className={classes.closeIcon}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 24 24"
            onClick={onClose}
          >
            <path
              stroke="gray"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18 17.94 6M18 18 6.06 6"
            />
          </svg>
        </div>
      </div>
    );
  };

  const renderComments = (postId) => {
    const postComments = comments[postId] || [];
    const rootComments = postComments.filter(
      (comment) => comment.isRootComment
    );
    const visibleRootComments = rootComments.slice(
      0,
      visibleComments[postId] || 10
    );

    return (
      <>
        {visibleRootComments.map((comment) => {
          const replyCount = postComments.filter(
            (reply) =>
              reply.rootCommentId === comment.id && !reply.isRootComment
          ).length;

          const topThreeEmojis = getTopThreeEmojis(comment.reactions || {});
          const totalReactions = Object.keys(comment.reactions || {}).length;

          return (
            <div key={comment.id} className={classes.commentItem}>
              <div className={classes.commentHeader}>
                <div className={classes.commentUserInfo}>
                  <img
                    src={comment.userImage}
                    alt={comment.userName}
                    className={classes.commentUserImage}
                    onClick={() => handleNavigate(comment.userId)}
                  />
                  <div className={classes.commentUserDetails}>
                    <div className={classes.commentUserNameContainer}>
                      <span
                        className={classes.commentUserName}
                        onClick={() => handleNavigate(comment.userId)}
                      >
                        {comment.userName}
                      </span>
                      <span className={classes.commentConnectionType}>
                        • {comment.connectionType}
                      </span>
                    </div>
                    {comment.bio && (
                      <p className={classes.commentUserBio}>{comment.bio}</p>
                    )}
                    {comment.link && (
                      <div className={classes.commentUserLinkContainer}>
                        <a
                          href={comment.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.commentUserLink}
                        >
                          <span className={classes.commentUserLinkText}>
                            {comment.linkText || comment.link}
                          </span>
                          <svg
                            className={classes.commentUserLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                    <div className={classes.commentTimeAndEditDetails}>
                      <p className={classes.commentTimestamp}>
                        {formatTimestamp(comment.createdAt)}
                      </p>
                      {comment.edited && (
                        <div className={classes.commentEditDot}>
                          •
                          <span className={classes.commentEditText}>
                            Edited
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.commentOptions}>
                  <svg
                    className={`${classes.commentOptionsIcon} ${
                      openCommentDropdown === `${postId}_${comment.id}`
                        ? classes.commentOptionsIconActive
                        : ""
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={() => toggleCommentDropdown(postId, comment.id)}
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeWidth="3"
                      d="M6 12h.01m6 0h.01m5.99 0h.01"
                    />
                  </svg>
                  {openCommentDropdown === `${postId}_${comment.id}` && (
                    <div className={classes.commentOptionsDropdown}>
                      {(currentUser.uid === comment.postUserId ||
                        comment.userId === currentUser.uid) && (
                        <button
                          className={classes.deleteCommentButton}
                          onClick={() =>
                            handleDeleteComment(postId, comment.id)
                          }
                        >
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="gray"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Delete comment
                        </button>
                      )}
                      {comment.userId === currentUser.uid && (
                        <button
                          className={classes.deleteCommentButton}
                          onClick={() => handleEditComment(postId, comment.id)}
                        >
                          <svg
                            class="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Zm-2.717 2.763L6.186 12.13l2.175 2.141 5.063-5.218-2.141-2.108Zm-6.25 6.886-1.98 5.849a.992.992 0 0 0 .245 1.026 1.03 1.03 0 0 0 1.043.242L10.282 19l-5.25-5.168Zm6.954 4.01 5.096-5.186-2.218-2.183-5.063 5.218 2.185 2.15Z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          Edit comment
                        </button>
                      )}
                      {currentUser.uid !== comment.postUserId &&
                        comment.userId !== currentUser.uid && (
                          <button
                            className={classes.reportCommentButton}
                            onClick={() =>
                              console.log(`Reporting comment: ${comment.id}`)
                            }
                          >
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              fill="gray"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 9v4m0 4h.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.069C2.842 18.4 3.808 20 5.313 20Z"
                              />
                            </svg>
                            Report comment
                          </button>
                        )}
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.commentContent} onClick={handleLinkClick}>
                {renderCommentText(postId, comment)}
              </div>
              {editingCommentId !== comment.id && (
                <div className={classes.commentActions}>
                  <div
                    className={classes.reactButton}
                    onClick={() =>
                      handleCommentReactionClick(postId, comment.id)
                    }
                  >
                    React
                  </div>
                  {totalReactions > 0 && (
                    <div className={classes.commentReactions}>
                      <div className={classes.commentEmojis}>
                        {topThreeEmojis.map((emoji, index) => (
                          <div
                            key={index}
                            className={classes.commentEmojiWrapper}
                          >
                            <span className={classes.emoji}>{emoji}</span>
                          </div>
                        ))}
                      </div>
                      <span>{totalReactions}</span>
                    </div>
                  )}
                  <div className={classes.dot}> • </div>
                  <div
                    className={classes.replyButton}
                    onClick={() => handleReplyButton(postId, comment.id)}
                  >
                    <span className={classes.replyText}>Reply</span>
                    {replyCount > 0 && (
                      <span className={classes.replyCount}>{replyCount}</span>
                    )}
                  </div>
                </div>
              )}
              {activeEmojiPicker === `${postId}_${comment.id}` && (
                <div className={classes.emojiPostPickerWrapper}>
                  <EmojiPicker
                    onEmojiClick={(emoji) =>
                      handleCommentEmojiSelect(postId, comment.id, emoji)
                    }
                    onClose={() => setActiveEmojiPicker(null)}
                  />
                </div>
              )}
              {renderReplies(postId, comment.id)}

              {activeCommentId[postId] === comment.id && (
                <div className={classes.replyCommentInputSection}>
                  <div className={classes.replyInputWrapper}>
                    <div>
                      <img
                        src={currentUserProfileImage || profileImage}
                        alt="Your profile"
                        className={classes.replyUserImage}
                        onClick={() => handleNavigate(currentUser.uid)}
                      />
                    </div>
                    <textarea
                      ref={(el) =>
                        (replyInputRef.current[postId] = {
                          ...replyInputRef.current[postId],
                          [comment.id]: el,
                        })
                      }
                      value={replyComments[postId]?.[comment.id] || ""}
                      onChange={(e) =>
                        handleReplyInputChange(e, postId, comment.id)
                      }
                      onTouchStart={handleTouchStart}
                      placeholder="Say something..."
                      className={classes.replyInput}
                    />
                  </div>
                  <div
                    onClick={() =>
                      replyComments[postId]?.[comment.id]?.trim() &&
                      handleAddComment(postId, comment.id)
                    }
                    className={`${classes.addReplyButton} ${
                      !replyComments[postId]?.[comment.id]?.trim()
                        ? classes.disabled
                        : ""
                    }`}
                  >
                    Reply
                  </div>
                </div>
              )}
            </div>
          );
        })}
        {rootComments.length > visibleRootComments.length && (
          <div
            className={classes.loadMoreCommentsButton}
            onClick={() => handleLoadMoreComments(postId)}
          >
            See more comments
          </div>
        )}
      </>
    );
  };

  const renderReplies = (postId, rootCommentId) => {
    const postComments = comments[postId] || [];
    const replies = postComments.filter(
      (comment) =>
        comment.rootCommentId === rootCommentId && !comment.isRootComment
    );
    const visibleRepliesCount = visibleReplies[postId]?.[rootCommentId] || 1;
    const visibleRepliesList = replies.slice(0, visibleRepliesCount);

    return (
      <div className={classes.repliesContainer}>
        {visibleRepliesList.map((reply) => {
          const topThreeEmojis = getTopThreeEmojis(reply.reactions || {});
          const totalReactions = Object.keys(reply.reactions || {}).length;

          return (
            <div key={reply.id} className={classes.replyItem}>
              <div className={classes.replyHeader}>
                <div className={classes.replyUserInfo}>
                  <img
                    src={reply.userImage}
                    alt={reply.userName}
                    className={classes.replyUserImage}
                    onClick={() => handleNavigate(reply.userId)}
                  />
                  <div className={classes.replyUserDetails}>
                    <div className={classes.replyUserNameContainer}>
                      <span
                        className={classes.replyUserName}
                        onClick={() => handleNavigate(reply.userId)}
                      >
                        {reply.userName}
                      </span>
                      <span className={classes.replyConnectionType}>
                        • {reply.connectionType}
                      </span>
                    </div>
                    {reply.bio && (
                      <p className={classes.replyUserBio}>{reply.bio}</p>
                    )}
                    {reply.link && (
                      <div className={classes.replyUserLinkContainer}>
                        <a
                          href={reply.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={(e) => e.stopPropagation()}
                          className={classes.replyUserLink}
                        >
                          <span className={classes.replyUserLinkText}>
                            {reply.linkText || reply.link}
                          </span>
                          <svg
                            className={classes.replyUserLinkIcon}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              stroke="orangered"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                            />
                          </svg>
                        </a>
                      </div>
                    )}
                    <div className={classes.commentTimeAndEditDetails}>
                      <p className={classes.commentTimestamp}>
                        {formatTimestamp(reply.createdAt)}
                      </p>
                      {reply.edited && (
                        <div className={classes.commentEditDot}>
                          •
                          <span className={classes.commentEditText}>
                            Edited
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className={classes.replyOptions}>
                  <svg
                    className={`${classes.replyOptionsIcon} ${
                      openCommentDropdown === `${postId}_${reply.id}`
                        ? classes.replyOptionsIconActive
                        : ""
                    }`}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    width="40"
                    height="40"
                    fill="none"
                    viewBox="0 0 24 24"
                    onClick={() => toggleCommentDropdown(postId, reply.id)}
                  >
                    <path
                      stroke="gray"
                      strokeLinecap="round"
                      strokeWidth="3"
                      d="M6 12h.01m6 0h.01m5.99 0h.01"
                    />
                  </svg>
                  {openCommentDropdown === `${postId}_${reply.id}` && (
                    <div className={classes.commentOptionsDropdown}>
                      {(currentUser.uid === reply.postUserId ||
                        reply.userId === currentUser.uid) && (
                        <button
                          className={classes.deleteCommentButton}
                          onClick={() => handleDeleteComment(postId, reply.id)}
                        >
                          <svg
                            className="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="gray"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                              clipRule="evenodd"
                            />
                          </svg>
                          Delete reply
                        </button>
                      )}
                      {reply.userId === currentUser.uid && (
                        <button
                          className={classes.deleteCommentButton}
                          onClick={() =>
                            handleEditComment(postId, reply.id, true)
                          }
                        >
                          <svg
                            class="w-6 h-6 text-gray-800 dark:text-white"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M14 4.182A4.136 4.136 0 0 1 16.9 3c1.087 0 2.13.425 2.899 1.182A4.01 4.01 0 0 1 21 7.037c0 1.068-.43 2.092-1.194 2.849L18.5 11.214l-5.8-5.71 1.287-1.31.012-.012Zm-2.717 2.763L6.186 12.13l2.175 2.141 5.063-5.218-2.141-2.108Zm-6.25 6.886-1.98 5.849a.992.992 0 0 0 .245 1.026 1.03 1.03 0 0 0 1.043.242L10.282 19l-5.25-5.168Zm6.954 4.01 5.096-5.186-2.218-2.183-5.063 5.218 2.185 2.15Z"
                              clip-rule="evenodd"
                            />
                          </svg>
                          Edit reply
                        </button>
                      )}
                      {currentUser.uid !== reply.postUserId &&
                        reply.userId !== currentUser.uid && (
                          <button
                            className={classes.reportCommentButton}
                            onClick={() =>
                              console.log(`Reporting reply: ${reply.id}`)
                            }
                          >
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              fill="gray"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 9v4m0 4h.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.069C2.842 18.4 3.808 20 5.313 20Z"
                              />
                            </svg>
                            Report reply
                          </button>
                        )}
                    </div>
                  )}
                </div>
              </div>
              <div className={classes.replyContent} onClick={handleLinkClick}>
                {renderReplyText(postId, reply)}
              </div>
              {editingCommentId !== reply.id && (
                <div className={classes.replyActions}>
                  <div
                    className={classes.reactButton}
                    onClick={() => handleCommentReactionClick(postId, reply.id)}
                  >
                    React
                  </div>
                  {totalReactions > 0 && (
                    <div className={classes.commentReactions}>
                      <div className={classes.commentEmojis}>
                        {topThreeEmojis.map((emoji, index) => (
                          <div
                            key={index}
                            className={classes.commentEmojiWrapper}
                          >
                            <span className={classes.emoji}>{emoji}</span>
                          </div>
                        ))}
                      </div>
                      <span>{totalReactions}</span>
                    </div>
                  )}
                  <div className={classes.dot}> • </div>
                  <button
                    className={classes.replyButton}
                    onClick={() => handleReplyButton(postId, reply.id)}
                  >
                    <span className={classes.replyText}>Reply</span>
                  </button>
                </div>
              )}
              {activeEmojiPicker === `${postId}_${reply.id}` && (
                <div className={classes.emojiPostPickerWrapper}>
                  <EmojiPicker
                    onEmojiClick={(emoji) =>
                      handleCommentEmojiSelect(postId, reply.id, emoji)
                    }
                    onClose={() => setActiveEmojiPicker(null)}
                  />
                </div>
              )}
              {activeCommentId[postId] === reply.id && (
                <div className={classes.replyToReplyCommentInputSection}>
                  <div className={classes.replyInputWrapper}>
                    <div>
                      <img
                        src={currentUserProfileImage || profileImage}
                        alt="Your profile"
                        className={classes.replyUserImage}
                        onClick={() => handleNavigate(currentUser.uid)}
                      />
                    </div>
                    <textarea
                      ref={(el) =>
                        (replyInputRef.current[postId] = {
                          ...replyInputRef.current[postId],
                          [reply.id]: el,
                        })
                      }
                      value={replyComments[postId]?.[reply.id] || ""}
                      onChange={(e) =>
                        handleReplyInputChange(e, postId, reply.id)
                      }
                      placeholder="Say something..."
                      className={classes.replyInput}
                    />
                  </div>
                  <div
                    onClick={() =>
                      replyComments[postId]?.[reply.id]?.trim() &&
                      handleAddComment(postId, reply.id)
                    }
                    className={`${classes.addReplyButton} ${
                      !replyComments[postId]?.[reply.id]?.trim()
                        ? classes.disabled
                        : ""
                    }`}
                  >
                    Reply
                  </div>
                </div>
              )}
            </div>
          );
        })}
        {replies.length > visibleRepliesCount && (
          <div
            className={classes.viewMoreRepliesButton}
            onClick={() => handleViewMoreReplies(postId, rootCommentId)}
          >
            See more replies
          </div>
        )}
      </div>
    );
  };

  const handleTouchStart = (e) => {
    const textarea = e.target;
    textarea.focus();
    if (isIOS) {
      forceKeyboardOpen(textarea);
    } else {
      openMobileKeyboard(textarea);
    }
  };

  const openMobileKeyboard = (inputElement) => {
    if (inputElement) {
      inputElement.focus();
      if (typeof inputElement.scrollIntoView === "function") {
        inputElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      // Try to force the keyboard to open
      if (typeof inputElement.click === "function") {
        inputElement.click();
      }
      // For iOS devices
      if (typeof inputElement.setSelectionRange === "function") {
        const length = inputElement.value.length;
        inputElement.setSelectionRange(length, length);
      }
    }
  };

  const PostedByMessage = ({ postUserId, currentUser }) => {
    if (postUserId !== currentUser?.uid) return null;

    return (
      <div className={classes.reactionHeader}>
        <img
          onClick={() => handleNavigate(userData.id)}
          src={currentUser.profileImage || profileImage}
          alt={`${currentUser.firstName} ${currentUser.lastName}`}
          className={classes.reactionImage}
        />
        <span className={classes.reactionText}>
          <span
            className={classes.reactionUsername}
            onClick={() => handleNavigate(userData.id)}
          >
            You
          </span>{" "}
          posted this
        </span>
      </div>
    );
  };

  const handleRepost = async (postId) => {
    if (!currentUser) return;

    const post = posts.find((p) => p.id === postId);
    if (!post) return;

    try {
      const postRef = doc(firestore, "users", userData.id, "posts", postId);
      const repostedPostRef = doc(
        firestore,
        "users",
        currentUser.uid,
        "reposts",
        postId
      );

      let updatedReposts = { ...(post.reposts || {}) };
      const now = serverTimestamp();

      if (updatedReposts[currentUser.uid]) {
        // Undo repost
        await updateDoc(postRef, {
          [`reposts.${currentUser.uid}`]: deleteField(),
        });
        delete updatedReposts[currentUser.uid];
        await deleteDoc(repostedPostRef);

        const notificationRef = doc(
          firestore,
          "users",
          userData.id,
          "notifications",
          `repost_${postId}_${currentUser.uid}`
        );
        await deleteDoc(notificationRef);

        setRepostMessage({
          text: "Repost removed.",
          postId,
          isRepost: false,
        });
      } else {
        // Repost
        await updateDoc(postRef, {
          [`reposts.${currentUser.uid}`]: now,
        });
        updatedReposts[currentUser.uid] = now;

        await setDoc(repostedPostRef, {
          originalPosterId: userData.id,
          postId: postId,
          repostedAt: now,
          originalDirectRepostTime: post.originalDirectRepostTime || now,
        });

        if (userData.id !== currentUser.uid) {
          const notificationRef = doc(
            firestore,
            "users",
            userData.id,
            "notifications",
            `repost_${postId}_${currentUser.uid}`
          );

          const currentUserDoc = await getDoc(
            doc(firestore, "users", currentUser.uid)
          );
          const currentUserData = currentUserDoc.data();

          await setDoc(notificationRef, {
            type: "repost",
            postId: postId,
            reposterId: currentUser.uid,
            reposterName: `${currentUserData.firstName} ${currentUserData.lastName}`,
            reposterImage: currentUserProfileImage || profileImage,
            createdAt: now,
            isNew: true,
            postPreview: {
              text: post.text || "",
              mediaType: post.content?.type || null,
              mediaUrl: post.content?.url || null,
              fileName: post.content?.fileName || null,
            },
          });
        }

        setRepostMessage({
          text: "Repost successful.",
          postId,
          isRepost: true,
        });
      }

      // Update local state
      setPosts((prevPosts) =>
        prevPosts.map((p) =>
          p.id === postId ? { ...p, reposts: updatedReposts } : p
        )
      );
    } catch (error) {
      console.error("Error in handleRepost:", error);
      setRepostMessage({
        text: `Error: ${error.message}`,
        postId,
        isRepost: false,
      });
    }
  };

  const handleRepostsClick = useCallback(
    async (postId, reposts) => {
      setRepostsModalScrollPosition(window.scrollY);
      const detailedReposts = await fetchRepostData(reposts);
      setActivePostReposts({ postId, reposts: detailedReposts });
      setShowRepostsModal((prev) => ({ ...prev, [postId]: true }));
      setIsRepostsModalOpen(true);
    },
    [fetchRepostData]
  );

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userDoc = await getDoc(doc(firestore, "users", user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setCurrentUser({
            ...user,
            firstName: userData.firstName,
            lastName: userData.lastName,
            profileImage: userData.profileImage || profileImage,
          });
          setCurrentUserProfileImage(userData.profileImage || profileImage);
        } else {
          setCurrentUser(user);
          setCurrentUserProfileImage(profileImage);
        }
      } else {
        setCurrentUser(null);
        setCurrentUserProfileImage(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchUserData = async () => {
    setLoading(true);
    try {
      let userId;
      if (urlSlug === "me") {
        if (!auth.currentUser) {
          console.error("No authenticated user found");
          setLoading(false);
          return;
        }
        userId = auth.currentUser.uid;
      } else {
        const usersRef = collection(firestore, "users");
        const q = query(usersRef, where("urlSlug", "==", urlSlug));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          userId = querySnapshot.docs[0].id;
        } else {
          console.error("User not found");
          setLoading(false);
          return;
        }
      }

      const userDoc = await getDoc(doc(firestore, "users", userId));
      if (userDoc.exists()) {
        setUserData({ id: userId, ...userDoc.data() });
      } else {
        console.error("User document not found");
        setLoading(false);
        return;
      }

      const postsRef = collection(firestore, `users/${userId}/posts`);
      const q = query(postsRef, orderBy("createdAt", "desc"));
      const querySnapshot = await getDocs(q);

      const fetchedPosts = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const postData = doc.data();
          let mediaUrl = null;

          if (
            postData.content &&
            postData.content.type &&
            postData.content.url
          ) {
            if (Array.isArray(postData.content.url)) {
              // Handle multi-image posts
              mediaUrl = await Promise.all(
                postData.content.url.map(async (url) => {
                  try {
                    const storageRef = ref(storage, url);
                    return await getDownloadURL(storageRef);
                  } catch (error) {
                    console.error("Error fetching media URL:", error);
                    return null;
                  }
                })
              );
            } else {
              // Handle single image/video posts
              try {
                const storageRef = ref(storage, postData.content.url);
                mediaUrl = await getDownloadURL(storageRef);
              } catch (error) {
                console.error("Error fetching media URL:", error);
              }
            }
          }

          let connectionType = "Extended";
          if (auth.currentUser) {
            connectionType = await checkConnectionType(
              auth.currentUser.uid,
              userId
            );
          }

          await fetchComments(doc.id, userId);

          return {
            id: doc.id,
            ...postData,
            user: {
              id: userId,
              firstName: userDoc.data().firstName,
              lastName: userDoc.data().lastName,
              profileImage: userDoc.data().profileImage || profileImage,
              bio: userDoc.data().bio,
              link: userDoc.data().link,
              linkText: userDoc.data().linkText,
            },
            content: {
              ...postData.content,
              url: mediaUrl || postData.content?.url,
            },
            reactions: postData.reactions || {},
            reposts: postData.reposts || {},
            commentCount: postData.commentCount || 0,
            connectionType: connectionType,
          };
        })
      );

      console.log("Fetched posts:", fetchedPosts);
      setPosts(fetchedPosts);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [urlSlug]);

  const checkConnectionType = async (currentUserId, targetUserId) => {
    if (currentUserId === targetUserId) return "You";

    const userConnectionRef = doc(
      firestore,
      `users/${currentUserId}/connections/${targetUserId}`
    );
    const userConnectionDoc = await getDoc(userConnectionRef);

    if (
      userConnectionDoc.exists() &&
      userConnectionDoc.data().status === "Connected"
    ) {
      return "Direct";
    }

    // Check for indirect connections
    const userConnectionsRef = collection(
      firestore,
      `users/${currentUserId}/connections`
    );
    const userConnectionsQuery = query(
      userConnectionsRef,
      where("status", "==", "Connected")
    );
    const userConnectionsSnapshot = await getDocs(userConnectionsQuery);

    for (const conn of userConnectionsSnapshot.docs) {
      const indirectConnectionRef = doc(
        firestore,
        `users/${conn.id}/connections/${targetUserId}`
      );
      const indirectConnectionDoc = await getDoc(indirectConnectionRef);
      if (
        indirectConnectionDoc.exists() &&
        indirectConnectionDoc.data().status === "Connected"
      ) {
        return "Indirect";
      }
    }

    return "Extended";
  };

  const fetchComments = useCallback(
    async (postId, postUserId) => {
      if (!postId || !postUserId) return;

      try {
        const commentsRef = collection(
          firestore,
          `users/${postUserId}/posts/${postId}/comments`
        );
        const q = query(commentsRef, orderBy("createdAt", "asc"));

        const snapshot = await getDocs(q);
        const fetchedComments = await Promise.all(
          snapshot.docs.map(async (docSnapshot) => {
            const commentData = docSnapshot.data();
            const userDocRef = doc(firestore, "users", commentData.userId);
            const userDocSnapshot = await getDoc(userDocRef);
            const userData = userDocSnapshot.data() || {};

            // Fetch the correct connectionType for the comment
            let connectionType = "Extended";
            if (currentUser) {
              connectionType = await checkConnectionType(
                currentUser.uid,
                commentData.userId
              );
            }

            return {
              id: docSnapshot.id,
              ...commentData,
              userName: `${userData.firstName || ""} ${
                userData.lastName || ""
              }`,
              userImage: userData.profileImage || profileImage,
              connectionType,
              bio: userData.bio || "",
              link: userData.link || null,
              linkText: userData.linkText || null,
              postId: postId,
              postUserId: postUserId,
              rootCommentId: commentData.rootCommentId,
              isRootComment: commentData.isRootComment,
            };
          })
        );

        setComments((prevComments) => ({
          ...prevComments,
          [postId]: fetchedComments,
        }));
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    },
    [currentUser, checkConnectionType]
  );

  useEffect(() => {
    if (posts.length > 0 && currentUser) {
      posts.forEach((post) => {
        if (!comments[post.id]) {
          fetchComments(post.id, post.user.id);
        }
      });
    }
  }, [posts, currentUser, fetchComments, checkConnectionType]);

  // Function to handle adding a comment
  const handleAddComment = async (postId, parentCommentId = null) => {
    if (!currentUser) return;

    const post = posts.find((p) => p.id === postId);
    if (!post) {
      console.error("Post not found");
      return;
    }

    let commentText;
    if (parentCommentId) {
      commentText = replyComments[postId]?.[parentCommentId] || "";
    } else {
      commentText = newComment[postId] || "";
    }

    if (!commentText.trim()) return;

    const processedCommentText = convertUrlsToLinks(commentText);

    try {
      const postRef = doc(firestore, `users/${post.user.id}/posts/${postId}`);
      const postSnap = await getDoc(postRef);
      if (!postSnap.exists()) {
        throw new Error("Post not found");
      }

      const rootCommentId = parentCommentId
        ? await getRootCommentId(post.user.id, postId, parentCommentId)
        : null;

      const currentUserDoc = await getDoc(
        doc(firestore, "users", currentUser.uid)
      );
      const currentUserData = currentUserDoc.data();

      const now = new Date();

      // Fetch the correct connectionType for the new comment
      let connectionType = await checkConnectionType(
        currentUser.uid,
        post.user.id
      );

      const commentData = {
        text: processedCommentText,
        userId: currentUser.uid,
        userName: `${currentUserData.firstName} ${currentUserData.lastName}`,
        userImage: currentUserData.profileImage || profileImage,
        createdAt: now,
        parentId: parentCommentId,
        rootCommentId: rootCommentId,
        isRootComment: !parentCommentId,
        bio: currentUserData.bio || "",
        connectionType: connectionType,
        link: currentUserData.link || null,
        linkText: currentUserData.linkText || null,
        postUserId: post.user.id,
      };

      const commentsRef = collection(
        firestore,
        `users/${post.user.id}/posts/${postId}/comments`
      );
      const newCommentRef = await addDoc(commentsRef, commentData);

      await updateDoc(newCommentRef, { createdAt: serverTimestamp() });

      await updateDoc(postRef, {
        commentCount: increment(1),
        [`comments.${currentUser.uid}`]: serverTimestamp(),
      });

      const newCommentObject = {
        id: newCommentRef.id,
        ...commentData,
        postId: postId,
        postUserId: post.user.id,
      };

      // Only update local state if the comment is from the current user
      if (currentUser.uid === commentData.userId) {
        setComments((prevComments) => ({
          ...prevComments,
          [postId]: [...(prevComments[postId] || []), newCommentObject],
        }));

        setPosts((prevPosts) =>
          prevPosts.map((p) =>
            p.id === postId
              ? {
                  ...p,
                  commentCount: (p.commentCount || 0) + 1,
                  comments: {
                    ...(p.comments || {}),
                    [currentUser.uid]: new Date(),
                  },
                }
              : p
          )
        );
      }

      if (parentCommentId) {
        setReplyComments((prev) => ({
          ...prev,
          [postId]: {
            ...(prev[postId] || {}),
            [parentCommentId]: "",
          },
        }));
      } else {
        setNewComment((prev) => ({ ...prev, [postId]: "" }));
        if (commentInputRef.current[postId]) {
          commentInputRef.current[postId].style.height = "auto";
        }
      }
      setActiveCommentId((prev) => ({ ...prev, [postId]: null }));

      const batch = writeBatch(firestore);

      if (post.user.id !== currentUser.uid && !parentCommentId) {
        const postOwnerNotificationRef = doc(
          firestore,
          `users/${post.user.id}/notifications`,
          `comment_${newCommentRef.id}`
        );
        batch.set(postOwnerNotificationRef, {
          type: "comment",
          postId: postId,
          commentId: newCommentRef.id,
          commenterId: currentUser.uid,
          commenterName: `${currentUserData.firstName} ${currentUserData.lastName}`,
          commenterImage: currentUserData.profileImage || profileImage,
          commentText: commentText,
          createdAt: now,
          isNew: true,
          postPreview: {
            text: post.text || "",
            mediaType: post.content?.type || null,
            mediaUrl: post.content?.url || null,
            fileName: post.content?.fileName || null,
          },
        });
      }

      if (parentCommentId) {
        const parentCommentRef = doc(commentsRef, parentCommentId);
        const parentCommentSnap = await getDoc(parentCommentRef);
        const parentCommentData = parentCommentSnap.exists()
          ? parentCommentSnap.data()
          : null;

        if (parentCommentData && parentCommentData.userId !== currentUser.uid) {
          const replyNotificationRef = doc(
            firestore,
            `users/${parentCommentData.userId}/notifications`,
            `reply_${newCommentRef.id}`
          );
          batch.set(replyNotificationRef, {
            type: "reply",
            postId: postId,
            commentId: newCommentRef.id,
            parentCommentId: parentCommentId,
            replierId: currentUser.uid,
            replierName: `${currentUserData.firstName} ${currentUserData.lastName}`,
            replierImage: currentUserData.profileImage || profileImage,
            replyText: commentText,
            createdAt: now,
            isNew: true,
            parentCommentPreview: {
              text: parentCommentData.text || "",
            },
            postPreview: {
              text: post.text || "",
              mediaType: post.content?.type || null,
              mediaUrl: post.content?.url || null,
              fileName: post.content?.fileName || null,
            },
          });
        }
      }

      await batch.commit();
    } catch (error) {
      console.error("Error adding comment:", error);
      setErrorPopup({
        message: "Failed to add comment.",
        isError: true,
      });
    }
  };

  // Function to get the root comment ID
  const getRootCommentId = async (postUserId, postId, commentId) => {
    try {
      const commentRef = doc(
        firestore,
        `users/${postUserId}/posts/${postId}/comments/${commentId}`
      );
      const commentSnap = await getDoc(commentRef);
      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }
      const commentData = commentSnap.data();

      if (commentData.isRootComment) {
        return commentId;
      } else {
        return commentData.rootCommentId;
      }
    } catch (error) {
      console.error("Error in getRootCommentId:", error);
      throw error;
    }
  };

  // Function to handle comment input change
  const handleCommentInputChange = (e, postId) => {
    const textarea = e.target;
    setNewComment((prev) => ({ ...prev, [postId]: textarea.value }));

    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  // Function to handle reply input change
  const handleReplyInputChange = (e, postId, replyId) => {
    const textarea = e.target;
    setReplyComments((prev) => ({
      ...prev,
      [postId]: {
        ...(prev[postId] || {}),
        [replyId]: textarea.value,
      },
    }));

    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight}px`;
  };

  // Function to handle reply button click
  const handleReplyButton = (postId, commentId) => {
    setActiveCommentId((prev) => ({
      ...prev,
      [postId]: prev[postId] === commentId ? null : commentId,
    }));
    setReplyComments((prev) => ({
      ...prev,
      [postId]: {
        ...(prev[postId] || {}),
        [commentId]: "",
      },
    }));

    setTimeout(() => {
      if (replyInputRef.current[postId]?.[commentId]) {
        const inputElement = replyInputRef.current[postId][commentId];
        inputElement.focus();
        inputElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });

        if (isIOS) {
          forceKeyboardOpen(inputElement);
        }
      }
    }, 100);
  };

  // Function to force open the keyboard on iOS
  const forceKeyboardOpen = (inputElement) => {
    if (inputElement) {
      inputElement.focus();
      inputElement.click();
      inputElement.setSelectionRange(0, 0);
      setTimeout(() => {
        inputElement.setSelectionRange(
          inputElement.value.length,
          inputElement.value.length
        );
      }, 50);
    }
  };

  // Function to handle loading more comments
  const handleLoadMoreComments = (postId) => {
    setVisibleComments((prev) => ({
      ...prev,
      [postId]: (prev[postId] || 10) + 10,
    }));
  };

  // Add this state
  const [isIOS, setIsIOS] = useState(false);

  // Add this useEffect
  useEffect(() => {
    // Detect if the device is running iOS
    const isIOSDevice =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    setIsIOS(isIOSDevice);
  }, []);

  // Function to handle viewing more replies
  const handleViewMoreReplies = (postId, rootCommentId) => {
    setVisibleReplies((prev) => ({
      ...prev,
      [postId]: {
        ...(prev[postId] || {}),
        [rootCommentId]: (prev[postId]?.[rootCommentId] || 1) + 9,
      },
    }));
  };

  // Function to toggle comments visibility
  const toggleComments = (postId) => {
    setShowComments((prev) => ({
      ...prev,
      [postId]: !prev[postId],
    }));
  };

  // Function to toggle comment expansion
  const toggleCommentExpansion = (postId, commentId) => {
    setExpandedComments((prev) => ({
      ...prev,
      [postId]: {
        ...(prev[postId] || {}),
        [commentId]: !prev[postId]?.[commentId],
      },
    }));
  };

  // Function to toggle reply expansion
  const toggleReplyExpansion = (postId, replyId) => {
    setExpandedReplies((prev) => ({
      ...prev,
      [postId]: {
        ...(prev[postId] || {}),
        [replyId]: !prev[postId]?.[replyId],
      },
    }));
  };

  // Function to handle comment reaction click
  const handleCommentReactionClick = (postId, commentId) => {
    setActiveEmojiPicker(
      activeEmojiPicker === `${postId}_${commentId}`
        ? null
        : `${postId}_${commentId}`
    );
  };

  // Function to handle comment emoji selection
  const handleCommentEmojiSelect = async (postId, commentId, emoji) => {
    if (commentId && currentUser) {
      try {
        let commentRef;
        let commentToUpdate;
        let isReply = false;

        const comment = comments[postId].find(
          (c) =>
            c.id === commentId || c.replies?.some((r) => r.id === commentId)
        );

        if (!comment) {
          throw new Error("Comment not found");
        }

        const postComments = comments[postId] || [];
        for (const comment of postComments) {
          if (comment.id === commentId) {
            commentRef = doc(
              firestore,
              `users/${comment.postUserId}/posts/${comment.postId}/comments/${commentId}`
            );
            commentToUpdate = comment;
            break;
          }
          if (comment.replies) {
            const reply = comment.replies.find((r) => r.id === commentId);
            if (reply) {
              commentRef = doc(
                firestore,
                `users/${comment.postUserId}/posts/${comment.postId}/comments/${comment.id}/replies/${commentId}`
              );
              commentToUpdate = reply;
              isReply = true;
              break;
            }
          }
        }

        if (!commentRef || !commentToUpdate) {
          throw new Error("Comment not found");
        }

        let updatedReactions = { ...(commentToUpdate.reactions || {}) };

        if (updatedReactions[currentUser.uid] === emoji) {
          delete updatedReactions[currentUser.uid];
        } else {
          updatedReactions[currentUser.uid] = emoji;
        }

        await updateDoc(commentRef, { reactions: updatedReactions });

        // Create notification for comment owner
        if (commentToUpdate.userId !== currentUser.uid) {
          const notificationRef = doc(
            firestore,
            `users/${commentToUpdate.userId}/notifications`,
            `comment_reaction_${commentId}_${currentUser.uid}`
          );

          // Fetch the current user's data
          const userDoc = await getDoc(
            doc(firestore, "users", currentUser.uid)
          );
          const userData = userDoc.data();

          await setDoc(notificationRef, {
            type: "comment_reaction",
            postId: postId,
            commentId: commentId,
            reactorId: currentUser.uid,
            reactorName: `${userData.firstName} ${userData.lastName}`,
            reactorImage: currentUserProfileImage || profileImage,
            emoji: emoji,
            createdAt: serverTimestamp(),
            isNew: true,
            commentText: commentToUpdate.text,
            isReply: isReply,
            postPreview: {
              text: commentToUpdate.postText || "",
              mediaType: commentToUpdate.postMediaType || null,
              mediaUrl: commentToUpdate.postMediaUrl || null,
              fileName: commentToUpdate.postFileName || null,
            },
          });
        }

        // Only update local state if the reaction is from the current user
        if (currentUser.uid === currentUser.uid) {
          setComments((prevComments) => ({
            ...prevComments,
            [postId]: prevComments[postId].map((comment) =>
              comment.id === commentId
                ? { ...comment, reactions: updatedReactions }
                : comment.replies
                ? {
                    ...comment,
                    replies: comment.replies.map((reply) =>
                      reply.id === commentId
                        ? { ...reply, reactions: updatedReactions }
                        : reply
                    ),
                  }
                : comment
            ),
          }));
        }
      } catch (error) {
        console.error("Error updating comment reaction:", error);
        setErrorPopup({
          message: error.message || "Error updating comment reaction",
          isError: true,
        });
      }
    }

    // Close the emoji picker
    setActiveEmojiPicker(null);
  };

  // Function to toggle comment dropdown
  const toggleCommentDropdown = (postId, commentId) => {
    setOpenCommentDropdown((prev) =>
      prev === `${postId}_${commentId}` ? null : `${postId}_${commentId}`
    );
  };

  // Function to handle comment deletion
  const handleDeleteComment = async (postId, commentId) => {
    if (!currentUser) {
      console.error("No current user");
      return;
    }

    try {
      // Find the post containing the comment
      const post = posts.find((p) => p.id === postId);

      if (!post) {
        console.error(`Post not found for comment: ${commentId}`);
        return;
      }

      const commentsRef = collection(
        firestore,
        `users/${post.user.id}/posts/${post.id}/comments`
      );

      const commentToDeleteRef = doc(commentsRef, commentId);
      const commentSnap = await getDoc(commentToDeleteRef);

      if (!commentSnap.exists()) {
        console.error("Comment does not exist");
        return;
      }

      const commentData = commentSnap.data();

      const canDelete =
        currentUser.uid === commentData.userId ||
        currentUser.uid === post.user.id;

      if (!canDelete) {
        console.error("User not authorized to delete this comment");
        return;
      }

      const batch = writeBatch(firestore);
      let deletedCount = 0;

      // If this is a root comment, delete all replies
      if (commentData.isRootComment) {
        const repliesQuery = query(
          commentsRef,
          where("rootCommentId", "==", commentId)
        );
        const repliesSnapshot = await getDocs(repliesQuery);

        repliesSnapshot.forEach((replyDoc) => {
          batch.delete(replyDoc.ref);
          deletedCount++;
        });
      }

      // Delete the comment itself
      batch.delete(commentToDeleteRef);
      deletedCount++;

      // Commit the batch
      await batch.commit();

      // Update local state
      setComments((prevComments) => ({
        ...prevComments,
        [postId]: prevComments[postId].filter(
          (c) => c.id !== commentId && c.rootCommentId !== commentId
        ),
      }));

      // Update post comment count
      const postRef = doc(firestore, `users/${post.user.id}/posts/${postId}`);
      await updateDoc(postRef, {
        commentCount: increment(-deletedCount),
      });

      setPosts((prevPosts) =>
        prevPosts.map((p) =>
          p.id === postId
            ? {
                ...p,
                commentCount: Math.max((p.commentCount || 0) - deletedCount, 0),
              }
            : p
        )
      );

      setOpenCommentDropdown(null);
    } catch (error) {
      console.error("Error deleting comment:", error);
      setErrorPopup({
        message: "Error deleting comment.",
        isError: true,
      });
    }
  };

  // Function to render comment text
  const renderCommentText = (postId, comment) => {
    if (!comment || typeof comment.text !== "string") return null;

    if (editingCommentId === comment.id) {
      return (
        <div>
          <textarea
            ref={editTextareaRef}
            value={editCommentText}
            onChange={handleEditTextareaChange}
            onFocus={handleEditTextareaFocus}
            className={classes.replyInput}
          />
          {renderEditButtons(postId, comment.id, false)}
        </div>
      );
    }

    const lines = comment.text.split("\n");
    const isLongComment = lines.length > 3 || comment.text.length > 149;

    const displayText =
      isLongComment && !expandedComments[postId]?.[comment.id]
        ? lines.slice(0, 3).join("\n").slice(0, 149)
        : comment.text;

    return (
      <div className={classes.commentTextContainer}>
        <div
          className={`${classes.commentText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{ __html: comment.text }}
        />
        {isLongComment && !expandedComments[postId]?.[comment.id] && (
          <button
            className={classes.showMoreButton}
            onClick={(e) => {
              e.stopPropagation();
              toggleCommentExpansion(postId, comment.id);
            }}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  // Function to render reply text
  const renderReplyText = (postId, reply) => {
    if (!reply || typeof reply.text !== "string") return null;

    if (editingCommentId === reply.id) {
      return (
        <div className={classes.editCommentSection}>
          <textarea
            ref={editTextareaRef}
            value={editCommentText}
            onChange={handleEditTextareaChange}
            onFocus={handleEditTextareaFocus}
            className={classes.editReplyInput}
          />
          {renderEditButtons(postId, reply.id, true)}
        </div>
      );
    }

    const lines = reply.text.split("\n");
    const isLongReply = lines.length > 3 || reply.text.length > 149;

    const displayText =
      isLongReply && !expandedReplies[postId]?.[reply.id]
        ? lines.slice(0, 3).join("\n").slice(0, 149)
        : reply.text;

    return (
      <div className={classes.replyTextContainer}>
        <div
          className={`${classes.replyText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{ __html: displayText }}
        />
        {isLongReply && !expandedReplies[postId]?.[reply.id] && (
          <button
            className={classes.showMoreButton}
            onClick={(e) => {
              e.stopPropagation();
              toggleReplyExpansion(postId, reply.id);
            }}
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  // Function to get top three emojis
  const getTopThreeEmojis = (reactions) => {
    if (!reactions) return [];
    const emojiCounts = Object.values(reactions).reduce((acc, emoji) => {
      acc[emoji] = (acc[emoji] || 0) + 1;
      return acc;
    }, {});
    return Object.entries(emojiCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 3)
      .map(([emoji]) => emoji);
  };

  const fetchCurrentUserProfileImage = async (uid) => {
    try {
      const userDoc = await getDoc(doc(firestore, "users", uid));
      if (userDoc.exists()) {
        setCurrentUserProfileImage(userDoc.data().profileImage || profileImage);
      }
    } catch (error) {
      console.error("Error fetching current user profile image:", error);
    }
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 648);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const formatTimestamp = (timestamp) => {
    if (!timestamp || typeof timestamp.toDate !== "function") return "";
    const now = new Date();
    const postDate = timestamp.toDate();
    const diffInSeconds = Math.floor((now - postDate) / 1000);

    if (diffInSeconds < 60) return "now";
    if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m`;
    if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h`;
    if (diffInSeconds < 604800) return `${Math.floor(diffInSeconds / 86400)}d`;
    if (diffInSeconds < 2592000)
      return `${Math.floor(diffInSeconds / 604800)}w`;
    if (diffInSeconds < 31536000)
      return `${Math.floor(diffInSeconds / 2592000)}mo`;
    return `${Math.floor(diffInSeconds / 31536000)}yr`;
  };

  const handleNavigate = async (userId) => {
    if (userId === currentUser?.uid) {
      navigate("/profile");
    } else {
      try {
        const userDoc = await getDoc(doc(firestore, "users", userId));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (userData.urlSlug) {
            navigate(`/${userData.urlSlug}`);
          } else {
            navigate(`/user/${userId}`);
          }
        } else {
          console.error("User not found");
          // Optionally, you can show an error message to the user
          setErrorPopup({
            message: "User not found.",
            isError: true,
          });
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        // Optionally, you can show an error message to the user
        setErrorPopup({
          message: "Error fetching user data.",
          isError: true,
        });
      }
    }
  };

  const renderPostText = (post) => {
    if (!post || !post.text) return null;
    const lines = post.text.split("\n");
    const isLongPost = lines.length > 3 || post.text.length > 149;
    const hasPdfAttachment = post.content && post.content.type === "pdf";

    if (!isLongPost) {
      return (
        <div
          className={`${classes.postTextContainer} ${
            hasPdfAttachment ? classes.postTextContainerNoInteractions : ""
          }`}
        >
          <div
            className={`${classes.postText} ${classes.preserveFormatting}`}
            dangerouslySetInnerHTML={{
              __html: convertUrlsToLinks(post.text),
            }}
            onClick={(e) => handleLinkClick(e, post.id)}
          />
        </div>
      );
    }

    const displayText = expandedPosts[post.id]
      ? post.text
      : lines.slice(0, 3).join("\n").slice(0, 149);

    return (
      <div
        className={`${classes.postTextContainer} ${
          hasPdfAttachment ? classes.postTextContainerNoInteractions : ""
        }`}
      >
        <span
          className={`${classes.postText} ${classes.preserveFormatting}`}
          dangerouslySetInnerHTML={{
            __html:
              convertUrlsToLinks(displayText) +
              (expandedPosts[post.id] ? "" : ""),
          }}
        />
        {!expandedPosts[post.id] && (
          <button
            className={classes.showMoreButton}
            onClick={() =>
              setExpandedPosts((prev) => ({ ...prev, [post.id]: true }))
            }
          >
            ...more
          </button>
        )}
      </div>
    );
  };

  const [currentImageIndex, setCurrentImageIndex] = useState({});

  const preloadImages = (urls) => {
    urls.forEach((url) => {
      if (url) {
        const img = new Image();
        img.src = url;
      }
    });
  };

  const handlePrevImage = (postId, totalImages) => {
    setCurrentImageIndex((prev) => ({
      ...prev,
      [postId]: ((prev[postId] || 0) - 1 + totalImages) % totalImages,
    }));
  };

  const handleNextImage = (postId, totalImages) => {
    setCurrentImageIndex((prev) => ({
      ...prev,
      [postId]: ((prev[postId] || 0) + 1) % totalImages,
    }));
  };

  const renderPostContent = (post) => {
    console.log("Rendering post content:", post.id, post.content);
    if (!post || !post.content) return null;

    switch (post.content.type) {
      case "image":
        return (
          <div className={classes.postContentWrapper}>
            <div className={classes.imageCarousel}>
              {Array.isArray(post.content.url) ? (
                <>
                  <img
                    src={post.content.url[currentImageIndex[post.id] || 0]}
                    alt={`Post content ${currentImageIndex[post.id] || 0}`}
                    className={classes.postContentImage}
                  />
                  {post.content.url.length > 1 && (
                    <div className={classes.dotNavigation}>
                      {post.content.url.map((_, index) => (
                        <span
                          key={index}
                          className={`${classes.dotNav} ${
                            index === (currentImageIndex[post.id] || 0)
                              ? classes.activeDot
                              : ""
                          }`}
                          onClick={(e) => {
                            e.stopPropagation();
                            setCurrentImageIndex((prev) => ({
                              ...prev,
                              [post.id]: index,
                            }));
                          }}
                        />
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <img
                  src={post.content.url}
                  alt="Post content"
                  className={classes.postContentImage}
                />
              )}
            </div>
          </div>
        );
      case "video":
        return (
          <div className={classes.postContentWrapper}>
            <div className={classes.videoContainer}>
              <video
                ref={(el) => (videoRefs.current[post.id] = el)}
                className={classes.postContentVideo}
                playsInline
                muted={!videoVolume[post.id]}
                loop
                autoPlay
                preload="metadata"
                poster={post.content.thumbnail || ""}
                onClick={(e) => {
                  if (e.target.paused) {
                    e.target.play();
                  } else {
                    e.target.pause();
                  }
                }}
              >
                <source src={post.content.url} type="video/mp4" />
              </video>
              <div className={classes.videoControls}>
                <button
                  className={classes.volumeControl}
                  onClick={(e) => {
                    e.stopPropagation();
                    setVideoVolume((prev) => ({
                      ...prev,
                      [post.id]: !prev[post.id],
                    }));
                  }}
                >
                  {!videoVolume[post.id] ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="white"
                      width="24px"
                      height="24px"
                    >
                      <path d="M16.5 12c0-1.77-1.02-3.29-2.5-4.03v2.21l2.45 2.45c.03-.2.05-.41.05-.63zm2.5 0c0 .94-.2 1.82-.54 2.64l1.51 1.51C20.63 14.91 21 13.5 21 12c0-4.28-2.99-7.86-7-8.77v2.06c2.89.86 5 3.54 5 6.71zM4.27 3L3 4.27 7.73 9H3v6h4l5 5v-6.73l4.25 4.25c-.67.52-1.42.93-2.25 1.18v2.06c1.38-.31 2.63-.95 3.69-1.81L19.73 21 21 19.73l-9-9L4.27 3zM12 4L9.91 6.09 12 8.18V4z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="white"
                      width="24px"
                      height="24px"
                    >
                      <path d="M3 9v6h4l5 5V4L7 9H3zm13.5 3c0-1.77-1.02-3.29-2.5-4.03v8.05c1.48-.73 2.5-2.25 2.5-4.02zM14 3.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77s-2.99-7.86-7-8.77z" />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
        );
      case "pdf":
        return (
          <div className={classes.postContentWrapper}>
            <a
              href={post.content.url}
              target="_blank"
              rel="noopener noreferrer"
              className={`${classes.pdfPreview} ${
                !hasInteractions(post) ? classes.pdfPreviewNoInteractions : ""
              }`}
            >
              <div className={classes.pdfBox}>
                <svg
                  className="w-6 h-6 text-gray-800 dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    fillRule="evenodd"
                    d="M9 2.221V7H4.221a2 2 0 0 1 .365-.5L8.5 2.586A2 2 0 0 1 9 2.22ZM11 2v5a2 2 0 0 1-2 2H4a2 2 0 0 0-2 2v7a2 2 0 0 0 2 2 2 2 0 0 0 2 2h12a2 2 0 0 0 2-2 2 2 0 0 0 2-2v-7a2 2 0 0 0-2-2V4a2 2 0 0 0-2-2h-7Zm-6 9a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h.5a2.5 2.5 0 0 0 0-5H5Zm1.5 3H6v-1h.5a.5.5 0 0 1 0 1Zm4.5-3a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h1.376A2.626 2.626 0 0 0 15 15.375v-1.75A2.626 2.626 0 0 0 12.375 11H11Zm1 5v-3h.375a.626.626 0 0 1 .625.626v1.748a.625.625 0 0 1-.626.626H12Zm5-5a1 1 0 0 0-1 1v5a1 1 0 1 0 2 0v-1h1a1 1 0 1 0 0-2h-1v-1h1a1 1 0 1 0 0-2h-2Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className={classes.fileContainer}>
                <div className={classes.fileAbout}>
                  <div className={classes.fileName}>
                    {(() => {
                      let fileName = "Unnamed PDF";
                      if (post.content.fileName) {
                        if (Array.isArray(post.content.fileName)) {
                          fileName = post.content.fileName[0] || "Unnamed PDF";
                        } else if (typeof post.content.fileName === "string") {
                          fileName = post.content.fileName;
                        }
                        // Remove .pdf extension if present
                        fileName = fileName.replace(/\.pdf$/i, "");
                      }
                      return fileName;
                    })()}
                  </div>
                  <div className={classes.open}>
                    View
                    <svg
                      className="w-6 h-6 text-gray-800 dark:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </a>
          </div>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const video = entry.target;
          video.play().catch((error) => console.log("Autoplay was prevented"));
        } else {
          entry.target.pause();
        }
      });
    }, options);

    Object.values(videoRefs.current).forEach((video) => {
      if (video) {
        observer.observe(video);
      }
    });

    return () => {
      Object.values(videoRefs.current).forEach((video) => {
        if (video) {
          observer.unobserve(video);
        }
      });
    };
  }, [posts]);

  const handleLinkClick = (e, postId) => {
    if (e.target.tagName === "A") {
      e.preventDefault();
      window.open(e.target.href, "_blank", "noopener,noreferrer");
    }
    // Remove the else block that was navigating to the post
  };

  const handleEmojiSelect = async (emoji, postId) => {
    if (!currentUser) return;

    try {
      const postRef = doc(firestore, "users", userData.id, "posts", postId);
      const reactedPostRef = doc(
        firestore,
        "users",
        currentUser.uid,
        "reactedPosts",
        postId
      );

      const postDoc = await getDoc(postRef);
      let updatedReactions = { ...(postDoc.data().reactions || {}) };

      if (updatedReactions[currentUser.uid] === emoji) {
        // User is removing their reaction
        await updateDoc(postRef, {
          [`reactions.${currentUser.uid}`]: deleteField(),
        });
        delete updatedReactions[currentUser.uid];
        await deleteDoc(reactedPostRef);

        // Remove the notification if it exists
        const notificationRef = doc(
          firestore,
          "users",
          userData.id,
          "notifications",
          `reaction_${postId}_${currentUser.uid}`
        );
        await deleteDoc(notificationRef);
      } else {
        // User is adding or changing their reaction
        await updateDoc(postRef, {
          [`reactions.${currentUser.uid}`]: emoji,
        });
        updatedReactions[currentUser.uid] = emoji;

        await setDoc(
          reactedPostRef,
          {
            originalPosterId: userData.id,
            postId: postId,
            reactedAt: serverTimestamp(),
            emoji: emoji,
          },
          { merge: true }
        );

        // Add notification if the post is not from the current user
        if (userData.id !== currentUser.uid) {
          const notificationRef = doc(
            firestore,
            "users",
            userData.id,
            "notifications",
            `reaction_${postId}_${currentUser.uid}`
          );

          // Fetch current user's data from Firestore
          const currentUserDoc = await getDoc(
            doc(firestore, "users", currentUser.uid)
          );
          const currentUserData = currentUserDoc.data();

          await setDoc(notificationRef, {
            type: "reaction",
            postId: postId,
            reactorId: currentUser.uid,
            reactorName: `${currentUserData.firstName} ${currentUserData.lastName}`,
            reactorImage: currentUserProfileImage || profileImage,
            emoji: emoji,
            createdAt: serverTimestamp(),
            isNew: true,
            postPreview: {
              text: postDoc.data().text || "",
              mediaType: postDoc.data().content?.type || null,
              mediaUrl: postDoc.data().content?.url || null,
              fileName: postDoc.data().content?.fileName || null,
            },
          });
        }
      }

      // Update local state
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post.id === postId ? { ...post, reactions: updatedReactions } : post
        )
      );

      // Close the EmojiPicker
      setActiveEmojiPicker(null);
    } catch (error) {
      console.error("Error updating reaction:", error);
    }
  };

  const handleReactionsClick = (postId, reactions) => {
    setReactionsModalScrollPosition(window.scrollY);
    setActivePostReactions({ postId, reactions });
    setShowReactionsModal((prev) => ({ ...prev, [postId]: true }));
    setIsReactionsModalOpen(true);
  };

  const handleDeletePost = async (postId) => {
    if (!currentUser || userData.id !== currentUser.uid) return;

    try {
      const postRef = doc(firestore, "users", userData.id, "posts", postId);

      // Delete the post
      await deleteDoc(postRef);

      // Delete associated notifications
      await deleteAssociatedNotifications(postId);

      // Update local state
      setPosts((prevPosts) => prevPosts.filter((post) => post.id !== postId));

      setDeleteMessage({
        text: "Post deleted.",
        postId,
        isSuccess: true,
      });
    } catch (error) {
      console.error("Error deleting post:", error);
      setDeleteMessage({
        text: "Failed to delete post",
        postId,
        isSuccess: false,
      });
    }
  };

  const deleteAssociatedNotifications = async (postId) => {
    const batch = writeBatch(firestore);
    const usersRef = collection(firestore, "users");
    const usersSnapshot = await getDocs(usersRef);

    for (const userDoc of usersSnapshot.docs) {
      const notificationsRef = collection(userDoc.ref, "notifications");
      const q = query(notificationsRef, where("postId", "==", postId));
      const notificationsSnapshot = await getDocs(q);

      notificationsSnapshot.forEach((notificationDoc) => {
        batch.delete(notificationDoc.ref);
      });
    }

    await batch.commit();
  };

  const handleReportPost = (postId) => {
    // Implement report post functionality
    console.log("Report post clicked for post:", postId);
  };

  const RepostCount = ({ reposts, onClick }) => {
    const repostCount = Object.keys(reposts || {}).length;
    if (repostCount === 0) return null;

    return (
      <div className={classes.repostCount} onClick={onClick}>
        <span>{repostCount}</span>
        <span> repost{repostCount !== 1 ? "s" : ""}</span>
      </div>
    );
  };

  const stripHtmlTags = (html) => {
    const tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  const handleEditComment = (postId, commentId, isReply = false) => {
    const postComments = comments[postId];
    if (!postComments) {
      console.error(`No comments found for post ${postId}`);
      return;
    }

    const commentToEdit = postComments.find((c) => c.id === commentId);
    if (commentToEdit) {
      setEditingCommentId(commentId);
      const strippedText = stripHtmlTags(commentToEdit.text);
      setEditCommentText(strippedText);
      setOpenCommentDropdown(null);

      setTimeout(() => {
        if (editTextareaRef.current) {
          editTextareaRef.current.focus();
          adjustTextareaHeight(editTextareaRef.current);
          const length = editTextareaRef.current.value.length;
          editTextareaRef.current.setSelectionRange(length, length);
        }
      }, 0);
    } else {
      console.error(`Comment ${commentId} not found in post ${postId}`);
    }
  };

  const handleSaveCommentChanges = async (
    postId,
    commentId,
    isReply = false
  ) => {
    if (!currentUser) return;

    const trimmedText = editCommentText.trim();

    if (trimmedText === "") {
      setErrorPopup({
        message: "Comment cannot be empty.",
        isError: true,
      });
      return;
    }

    try {
      const post = posts.find((p) => p.id === postId);
      if (!post) throw new Error("Post not found");

      const commentRef = doc(
        firestore,
        `users/${post.user.id}/posts/${postId}/comments/${commentId}`
      );

      const commentSnap = await getDoc(commentRef);
      if (!commentSnap.exists()) {
        throw new Error("Comment not found");
      }

      const commentData = commentSnap.data();

      const processedText = convertUrlsToLinks(trimmedText);

      await updateDoc(commentRef, {
        text: processedText,
        edited: true,
      });

      setComments((prevComments) => ({
        ...prevComments,
        [postId]: prevComments[postId].map((comment) =>
          comment.id === commentId
            ? { ...comment, text: processedText, edited: true }
            : comment
        ),
      }));

      setEditingCommentId(null);
      setEditCommentText("");

      console.log("Comment updated successfully");
    } catch (error) {
      console.error("Error updating comment:", error);
      setErrorPopup({
        message: "Error updating comment.",
        isError: true,
      });
    }
  };

  const handleCancelEdit = () => {
    setEditingCommentId(null);
    setEditCommentText("");
  };

  const adjustTextareaHeight = (textarea) => {
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleEditTextareaChange = (e) => {
    const textarea = e.target;
    setEditCommentText(textarea.value);
    adjustTextareaHeight(textarea);
  };

  const handleEditTextareaFocus = (e) => {
    const textarea = e.target;
    if (isIOS) {
      forceKeyboardOpen(textarea);
    } else {
      openMobileKeyboard(textarea);
    }
  };

  useEffect(() => {
    if (editingCommentId && editTextareaRef.current) {
      adjustTextareaHeight(editTextareaRef.current);
    }
  }, [editingCommentId]);

  const renderEditButtons = (postId, commentId, isReply = false) => (
    <div className={classes.editCommentButtons}>
      <div
        className={`${classes.saveChangesButton} ${
          editCommentText.trim() === "" ? classes.disabledButton : ""
        }`}
        onClick={() =>
          editCommentText.trim() !== "" &&
          handleSaveCommentChanges(postId, commentId, isReply)
        }
      >
        Save changes
      </div>
      <div className={classes.dot}> • </div>
      <div
        className={classes.cancelEditButton}
        onClick={() => handleCancelEdit()}
      >
        Cancel
      </div>
    </div>
  );

  const handleEditComplete = async (updatedText, isEdited) => {
    try {
      const postRef = doc(
        firestore,
        `users/${userData.id}/posts/${editingPost.id}`
      );
      const updateData = {
        text: updatedText,
        isEdited: isEdited,
      };

      await updateDoc(postRef, updateData);

      // Update local state
      setPosts((prevPosts) =>
        prevPosts.map((post) =>
          post.id === editingPost.id
            ? { ...post, text: updatedText, isEdited: isEdited }
            : post
        )
      );

      setIsEditingComplete(true);
      setShowEditModal(false);
      setEditingPost(null);
      setIsPostModalOpen(false);
    } catch (error) {
      console.error("Error updating post:", error);
      setErrorPopup({
        message: "Error updating post.",
        isError: true,
      });
    }
  };

  useEffect(() => {
    if (isEditingComplete) {
      window.scrollTo(0, postModalScrollPosition);
      setIsEditingComplete(false);
    }
  }, [isEditingComplete, postModalScrollPosition]);

  return (
    <div
      className={`${classes.page} ${
        isReactionsModalOpen || isRepostsModalOpen || isPostModalOpen
          ? classes.noScroll
          : ""
      }`}
      style={
        isReactionsModalOpen
          ? { top: `-${reactionsModalScrollPosition}px` }
          : isRepostsModalOpen
          ? { top: `-${repostsModalScrollPosition}px` }
          : isPostModalOpen
          ? { top: `-${postModalScrollPosition}px` }
          : {}
      }
    >
      {isMobile ? (
        <MobileNavbar userImage={currentUser?.profileImage || profileImage} />
      ) : (
        <MainNavbar userImage={currentUser?.profileImage || profileImage} />
      )}
      {showEditModal && editingPost && (
        <PostModal
          onClose={() => {
            setShowEditModal(false);
            setEditingPost(null);
            setIsPostModalOpen(false);
            setTimeout(() => {
              window.scrollTo(0, postModalScrollPosition);
            }, 0);
          }}
          currentUser={currentUser}
          initialPost={editingPost}
          onEditComplete={handleEditComplete}
        />
      )}

      {repostMessage && (
        <RepostPopup
          message={repostMessage.text}
          postId={repostMessage.postId}
          isRepost={repostMessage.isRepost}
          onClose={() => setRepostMessage(null)}
        />
      )}
      {deleteMessage && (
        <PostActionPopup
          message={deleteMessage.text}
          postId={deleteMessage.postId}
          isDelete={true}
          isSuccess={deleteMessage.isSuccess}
          onClose={() => setDeleteMessage(null)}
        />
      )}

      <div className={classes.content}>
        <div className={classes.centreCards}>
          {loading ? (
            <div className={classes.loadingContainer}>
              <span
                className={`material-symbols-outlined ${classes.loadingIcon}`}
              >
                progress_activity
              </span>
            </div>
          ) : (
            <div className={classes.postsContainer}>
              {posts.map((post) => (
                <div key={post.id} className={classes.postCard}>
                  <div className={classes.reactionHeader}>
                    <img
                      src={post.user?.profileImage || profileImage}
                      alt={`${post.user?.firstName || ""} ${
                        post.user?.lastName || ""
                      }`}
                      className={classes.reactionImage}
                      onClick={() => post.user && handleNavigate(post.user.id)}
                      style={{ cursor: "pointer" }}
                    />
                    <div className={classes.reactionTextWrapper}>
                      <span className={classes.reactionText}>
                        <span
                          className={classes.reactionUsername}
                          onClick={() =>
                            post.user && handleNavigate(post.user.id)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          {post.user?.id === currentUser?.uid
                            ? "You"
                            : `${post.user?.firstName || ""} ${
                                post.user?.lastName || ""
                              }`}
                        </span>{" "}
                        posted this
                      </span>
                    </div>
                  </div>
                  <div className={classes.postHeader}>
                    <div className={classes.userInfo}>
                      <img
                        className={classes.postImage}
                        src={post.user?.profileImage || profileImage}
                        alt=""
                        onClick={() =>
                          post.user && handleNavigate(post.user.id)
                        }
                        style={{ cursor: "pointer" }}
                      />
                      <div className={classes.userDetails}>
                        <div className={classes.userNameContainer}>
                          <p
                            className={classes.userName}
                            onClick={() =>
                              post.user && handleNavigate(post.user.id)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <span className={classes.nameText}>
                              {`${post.user?.firstName || ""} ${
                                post.user?.lastName || ""
                              }`}
                            </span>
                          </p>
                          {post.connectionType && (
                            <span className={classes.connectionType}>
                              • {post.connectionType}
                            </span>
                          )}
                        </div>

                        {post.user?.bio && (
                          <p className={classes.bio}>{post.user.bio}</p>
                        )}
                        {post.user?.link && (
                          <div className={classes.userLinkContainer}>
                            <a
                              href={post.user.link}
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={(e) => e.stopPropagation()}
                              className={classes.userLink}
                            >
                              <span className={classes.userLinkText}>
                                {post.user.linkText || post.user.link}
                              </span>
                              <svg
                                className={classes.userLinkIcon}
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                fill="none"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  stroke="orangered"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M18 14v4.833A1.166 1.166 0 0 1 16.833 20H5.167A1.167 1.167 0 0 1 4 18.833V7.167A1.166 1.166 0 0 1 5.167 6h4.618m4.447-2H20v5.768m-7.889 2.121 7.778-7.778"
                                />
                              </svg>
                            </a>
                          </div>
                        )}
                        <div className={classes.timeAndEditDetails}>
                          {post.createdAt && (
                            <p className={classes.time}>
                              {formatTimestamp(post.createdAt)}
                            </p>
                          )}
                          {post.isEdited && (
                            <div className={classes.editDot}>
                              •
                              <span className={classes.editedPostText}>
                                Edited
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className={classes.postOptions}>
                      <svg
                        className={`${classes.optionsIcon} ${
                          showDropdown[post.id] ? classes.optionsIconActive : ""
                        }`}
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="40"
                        fill="none"
                        viewBox="0 0 24 24"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowDropdown((prev) => ({
                            ...prev,
                            [post.id]: !prev[post.id],
                          }));
                        }}
                      >
                        <path
                          stroke="gray"
                          strokeLinecap="round"
                          strokeWidth="3"
                          d="M6 12h.01m6 0h.01m5.99 0h.01"
                        />
                      </svg>
                      {showDropdown[post.id] && (
                        <div className={classes.optionsDropdown}>
                          {userData.id === currentUser?.uid && (
                            <>
                              <button onClick={() => handleEditPost(post)}>
                                <svg
                                  className="w-6 h-6 text-gray-800 dark:text-white"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M14 4.182c.782-.738 1.793-1.182 2.89-1.182 1.089 0 2.136.44 2.898 1.214.758.77 1.212 1.799 1.212 2.889 0 1.092-.438 2.135-1.194 2.904l-1.274 1.283-5.816-5.71 1.285-1.398Zm-2.722 2.77L6.186 12.12l2.187 2.153 5.069-5.224-2.164-2.098Zm-6.299 6.914-1.966 5.812a1.015 1.015 0 0 0 .248 1.018 1.043 1.043 0 0 0 1.04.246l5.876-1.934-5.198-5.142Zm6.984 4.02 5.092-5.182-2.215-2.18-5.068 5.223 2.191 2.139Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                Edit post
                              </button>
                              <button onClick={() => handleDeletePost(post.id)}>
                                <svg
                                  className="w-6 h-6 text-gray-800 dark:text-white"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="gray"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M8.586 2.586A2 2 0 0 1 10 2h4a2 2 0 0 1 2 2v2h3a1 1 0 1 1 0 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V8a1 1 0 0 1 0-2h3V4a2 2 0 0 1 .586-1.414ZM10 6h4V4h-4v2Zm1 4a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Zm4 0a1 1 0 1 0-2 0v8a1 1 0 1 0 2 0v-8Z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                Delete post
                              </button>
                            </>
                          )}
                          <button onClick={() => handleReportPost(post.id)}>
                            <svg
                              className="w-6 h-6 text-gray-800 dark:text-white"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                stroke="gray"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 9v4m0 4h.01M5.313 20h13.374c1.505 0 2.471-1.6 1.77-2.931L13.77 4.363c-.75-1.425-2.79-1.425-3.54 0L3.543 17.069C2.842 18.4 3.808 20 5.313 20Z"
                              />
                            </svg>
                            Report post
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  {post.text && renderPostText(post)}
                  {renderPostContent(post)}
                  {(hasInteractions(post) || post.commentCount > 0) && (
                    <div className={classes.postInteractionsInfo}>
                      {post.reactions &&
                        Object.keys(post.reactions).length > 0 && (
                          <div
                            className={classes.reactionsSummary}
                            onClick={() =>
                              handleReactionsClick(post.id, post.reactions)
                            }
                          >
                            <div className={classes.reactionStack}>
                              {getTopThreeEmojis(post.reactions)
                                .reverse()
                                .map((emoji, index) => (
                                  <div
                                    key={index}
                                    className={classes.reactionEmoji}
                                    style={{
                                      zIndex:
                                        getTopThreeEmojis(post.reactions)
                                          .length - index,
                                      fontSize: "var(--emoji-font-size)",
                                      marginLeft: "var(--emoji-margin-left)",
                                    }}
                                  >
                                    {emoji}
                                  </div>
                                ))}
                            </div>
                            <span className={classes.reactionCount}>
                              {Object.keys(post.reactions).length}
                            </span>
                          </div>
                        )}
                      <div className={classes.rightInteractions}>
                        {post.commentCount > 0 && (
                          <span
                            className={`${classes.commentCount} ${
                              showComments[post.id]
                                ? classes.activeCommentCount
                                : ""
                            }`}
                            onClick={() => toggleComments(post.id)}
                          >
                            {post.commentCount} comment
                            {post.commentCount !== 1 ? "s" : ""}
                          </span>
                        )}
                        {post.commentCount > 0 &&
                          post.reposts &&
                          Object.keys(post.reposts).length > 0 && (
                            <span className={classes.dot}>•</span>
                          )}
                        {post.reposts &&
                          Object.keys(post.reposts).length > 0 && (
                            <span
                              className={classes.repostCount}
                              onClick={() =>
                                handleRepostsClick(post.id, post.reposts)
                              }
                            >
                              {Object.keys(post.reposts).length} repost
                              {Object.keys(post.reposts).length !== 1
                                ? "s"
                                : ""}
                            </span>
                          )}
                      </div>
                    </div>
                  )}
                  <div className={classes.postInteractions}>
                    <button
                      className={`${classes.interactionButton} ${
                        post.reactions?.[currentUser?.uid]
                          ? classes.emojiButton
                          : ""
                      }`}
                      onClick={() => {
                        if (post.reactions?.[currentUser?.uid]) {
                          handleEmojiSelect(
                            post.reactions[currentUser.uid],
                            post.id
                          );
                        } else {
                          setActiveEmojiPicker(
                            activeEmojiPicker === post.id ? null : post.id
                          );
                        }
                      }}
                    >
                      {post.reactions?.[currentUser?.uid] ? (
                        <span>{post.reactions[currentUser.uid]}</span>
                      ) : (
                        <svg
                          className="w-6 h-6 text-gray-800 dark:text-white"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M14.99 9H15M8.99 9H9m12 3a9 9 0 1 1-18 0 9 9 0 0 1 18 0ZM7 13c0 1 .507 2.397 1.494 3.216a5.5 5.5 0 0 0 7.022 0C16.503 15.397 17 14 17 13c0 0-1.99 1-4.995 1S7 13 7 13Z"
                          />
                        </svg>
                      )}
                    </button>
                    <button
                      className={classes.interactionButton}
                      onClick={() => toggleComments(post.id)}
                    >
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M7 9h5m3 0h2M7 12h2m3 0h5M5 5h14a1 1 0 0 1 1 1v9a1 1 0 0 1-1 1h-6.616a1 1 0 0 0-.67.257l-2.88 2.592A.5.5 0 0 1 8 18.477V17a1 1 0 0 0-1-1H5a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1Z"
                        />
                      </svg>
                    </button>
                    <button
                      className={`${classes.interactionButton} ${
                        post.reposts?.[currentUser?.uid]
                          ? classes.repostedButton
                          : ""
                      }`}
                      onClick={() => handleRepost(post.id)}
                    >
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m16 10 3-3m0 0-3-3m3 3H5v3m3 4-3 3m0 0 3 3m-3-3h14v-3"
                        />
                      </svg>
                    </button>
                    <button className={classes.interactionButton}>
                      <svg
                        className="w-6 h-6 text-gray-800 dark:text-white"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m12 18-7 3 7-18 7 18-7-3Zm0 0v-5"
                        />
                      </svg>
                    </button>
                  </div>

                  {activeEmojiPicker === post.id && (
                    <div className={classes.emojiPickerWrapper}>
                      <EmojiPicker
                        onEmojiClick={(emoji) =>
                          handleEmojiSelect(emoji, post.id)
                        }
                        onClose={() => setActiveEmojiPicker(null)}
                      />
                    </div>
                  )}
                  {showComments[post.id] && (
                    <div className={classes.commentsSection}>
                      <div className={classes.commentInputSection}>
                        <div className={classes.commentInputWrapper}>
                          <div>
                            <img
                              src={currentUser?.profileImage || profileImage}
                              alt="Your profile"
                              className={classes.commentUserImage}
                              onClick={() =>
                                currentUser && handleNavigate(currentUser.uid)
                              }
                            />
                          </div>
                          <textarea
                            ref={(el) =>
                              (commentInputRef.current[post.id] = el)
                            }
                            value={newComment[post.id] || ""}
                            onChange={(e) =>
                              handleCommentInputChange(e, post.id)
                            }
                            placeholder="Say something..."
                            className={classes.commentInput}
                          />
                        </div>
                        <div
                          onClick={() =>
                            currentUser &&
                            newComment[post.id]?.trim() &&
                            handleAddComment(post.id)
                          }
                          className={`${classes.addCommentButton} ${
                            !newComment[post.id]?.trim() ? classes.disabled : ""
                          }`}
                        >
                          Comment
                        </div>
                      </div>
                      <div className={classes.commentsList}>
                        {renderComments(post.id)}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}

          {isReactionsModalOpen && activePostReactions && (
            <ReactionsModal
              onClose={() => {
                setShowReactionsModal((prev) => ({
                  ...prev,
                  [activePostReactions.postId]: false,
                }));
                setIsReactionsModalOpen(false);
                setTimeout(() => {
                  window.scrollTo(0, reactionsModalScrollPosition);
                }, 0);
              }}
              postId={activePostReactions.postId}
              reactions={activePostReactions.reactions || {}}
              currentUserId={currentUser?.uid}
            />
          )}

          {isRepostsModalOpen && activePostReposts && (
            <RepostsModal
              onClose={() => {
                setShowRepostsModal((prev) => ({
                  ...prev,
                  [activePostReposts.postId]: false,
                }));
                setIsRepostsModalOpen(false);
                setTimeout(() => {
                  window.scrollTo(0, repostsModalScrollPosition);
                }, 0);
              }}
              postId={activePostReposts.postId}
              reposts={activePostReposts.reposts || {}}
              currentUserId={currentUser?.uid}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default AllPosts;
