import React, { useEffect, useState } from "react";
import MainNavbar from "../../../components/Navbars/MainNavbar";
import MobileNavbar from "../../../components/Navbars/MobileNavbar";
import classes from "../../Main/Profile/Profile.module.css";
import { firestore, auth } from "../../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import profileImage from "../../../assets/icons/profileImage.jpg";

function Messages() {
  const [userData, setUserData] = useState({
    firstName: "",
    lastName: "",
    city: "",
    country: "",
    bio: "",
    profileImage: "",
  });

  const [currentUser, setCurrentUser] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 767);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 767);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchUserData = async (userId) => {
      try {
        const userDoc = await getDoc(doc(firestore, "users", userId));
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        }
      } catch (error) {}
    };

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setCurrentUser(user);
        fetchUserData(user.uid);
      } else {
        setCurrentUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const userImage = userData.profileImage || profileImage;

  return (
    <div className={classes.page}>
      {isMobile ? (
        <MobileNavbar userImage={userImage} />
      ) : (
        <MainNavbar userImage={userImage} />
      )}
    </div>
  );
}

export default Messages;
